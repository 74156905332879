p {
	font-size: 1.1rem;
	line-height: 1.5;
	color: #000;
}

video, iframe.video {
	width: 100%;
	height: auto;
	object-fit: fill;
	display: flex;
}

iframe.video {
	min-height: 195px;
}

.justify-content-center {
	justify-content: center;
}

.justify-content-flex-end {
	justify-content: flex-end !important;
}

.floated {
	&.left {
		float: left !important;
	}
	&.right {
		float: right !important;
	}
}

.aligned {
	&.right {
		text-align: right !important;
	}
}

.ui.form .field>.floating.selection.dropdown {
  border-radius: 100px !important;

  .menu {
    border-radius: 1rem !important;
  }

  >.label {
    border-radius: 100px;
  }
}



.ui.star.rating .active.icon {
	color: #ffbc00 !important;
	text-shadow: none !important;
}

.image.rating {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
  	flex: 1;
    max-width: 18px;
    margin: 0 .2rem;
    width: 100%;
  }
}

@keyframes waving {
	0% {
		transform: translate(-50%, -75%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -75%) rotate(360deg);
	}
}

.waving.btn {
  text-align: center;
  position: relative;
  padding: 1.25rem 1rem;
  text-decoration: none;
  overflow: hidden;
  border-radius: 100px;
  box-sizing: border-box;
  border: none;
  width: 100%;
  margin-top: 0.5rem;
  cursor: pointer;

	&:hover {
		.liquid {
			top: -200px;
		}
	}

	span.text {
    position: relative;
    color: #fff;
    font-size: 1.5rem;
    z-index: 1;
    font-weight: 600;
    font-family: 'Valexa';
	}

	span.liquid {
    position: absolute;
    display: block;
    top: -145px;
    left: 0;
    width: 100%;
    height: 300px;
    background: #ff7878;
    transition: 0.5s;
	
		&::after {
			content: "";
			width: 200%;
			height: 200%;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -75%);
			background: #000;
			border-radius: 40%;
			background: rgb(255 255 255 / 50%);
			animation: waving 10s linear infinite;
		}
		
		&::before {
			content: "";
			width: 200%;
			height: 200%;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -75%);
			background: #000;
			border-radius: 45%;
			background: rgb(255 255 255);
			animation: waving 5s linear infinite;
		}
	}
}

.ui.button {
	&.yellow
	{
		background: #ffe73e !important;
	  color: #000 !important;

	  &:hover {
	    background: #ffba20 !important;
	    color: #000 !important;
		}
	}

	&.white {
		background: #fff !important;
	  color: #000 !important;

	  &:hover {
	    background: #fff !important;
	    color: #000 !important;
		}
	}
}

@keyframes cursor-colors {
	0% {
		filter: hue-rotate(0deg);
	}
	100% {
		filter: hue-rotate(360deg);
	}
}

#cursor {
  z-index: 999;
  position: fixed;
  background: rgb(63 133 255 / 68%);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 0 0 20px 10px rgb(63 133 255 / 68%);
  animation: cursor-colors 5s infinite;
  transform: translate(-50%, -50%);
  display: none;
  opacity: 0.8;

  &:before {
		content: '';
		position: absolute;
		width: 50px;
		height: 50px;
		opacity: 0.2;
		transform: translate(-30%, -30%);
		border-radius: 50%;
	}
}


.circular-corner {
	border-radius: 500px !important;
}

.rounded-corner {
	border-radius: 1rem !important;
}

.ui.message {
	&.white {
		background: #fff;
	}
}

.ui.pagination.menu {
	border-radius: .75rem;

	.item {
		font-size: 1.1rem;
	}
}

h2 {
	font-size: 1.5rem;
}


a {
    opacity: 1 !important;
    color: #000;
    cursor: pointer;
}

.bold, strong {
	font-weight: 600;
}

.overflow-hidden {
	overflow: hidden;
}


input {
	&.error {
	  background: #FFF6F6 !important;
	  border-color: #E0B4B4 !important;
	  color: #9F3A38 !important;
	}
}

html, body
{
    height: auto !important;
    background: #fff;
    max-width: 1600px;
    margin: 0 auto;
    color: #000;
    overflow: hidden;
}

.ui.modal.modal-screenshots {
  width: fit-content !important;
  max-width: fit-content !important;
  display: flex !important;
  margin: -1rem !important;
  overflow: hidden;

  .content {
	  padding: 0 !important;
	  display: flex !important;
	  background: transparent !important;
	}

	img {
    max-height: 100vh;
    width: auto;
    height: auto;
    max-width: 100vw;
	}
}

.main.container {
  width: 100% !important;
  background: #fff;
  margin: 0 auto !important;
}


#user-message {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	align-items: center;
	justify-content: center;
  z-index: 999999;
  background: radial-gradient(rgb(246, 246, 246), rgb(246 246 246 / 77%), rgb(255, 255, 255));
  backdrop-filter: invert(1);

	&.active {
		display: flex;
	}

  .container {
    max-width: 400px;
    width: 100%;
    background: #fff;
    position: relative;
    z-index: 99999;
    border-radius: 1rem;
	}

	.content {
	  > div {
		  padding: 1.5rem;
		}

		.icon {
	    padding-bottom: 0;
	    display: flex;

	    img {
				width: 60px;
				margin: auto;
	    }
		}

		.text {
			font-weight: 500;
			color: #000;
			line-height: 1.5;
			font-size: 1.1rem;
			padding-top: 1rem;
			text-align: justify;
		}
	}

	.action {
    padding: 1.5rem 1.5rem;
    text-align: center;
    background: #ececec;
    border-radius: 0 0 1rem 1rem;

    button {
		  min-width: 180px;
		  border-radius: 100px;
		  margin: auto;
		}
	}
}


#cookie {
  display: none;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  max-width: 300px;
  width: 100%;
  background: #fff;
  z-index: 99999;
  border-radius: 1rem;
  box-shadow: 0 0 40px 40px rgb(0 0 0 / 7%);
    
	&.active {
		display: block;
	}

	.content {
	  > div {
		  padding: 1.5rem;
		}

		.icon {
			background: #fff;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    padding: 1rem;
	    border-radius: 1rem 1rem 0 0;

	    img {
		    width: 60px;
		    margin-right: 2rem;
	    }

	    span {
			  font-size: 1.5rem;
			  font-weight: 700;
			  line-height: 1.5;
			  font-family: system-ui;
			}
		}

		.text {
			font-weight: 500;
			color: #353535;
			line-height: 1.5;
			font-size: 1rem;
			padding-top: 1rem;
			background: #f2f3ff;

			* {
			  color: #353535;
			}
		}
	}

	.action {
		padding: 1.5rem 1.5rem;
		background: #fff;
		border-radius: 0 0 1rem 1rem;

    button {
	    min-width: 180px;
	    border-radius: 0.75rem;
		}
	}
}


#maintenance-page {
	min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

	.row {
		flex-direction: column;
	}


	.timeout-wrapper {
    display: flex;
    margin-bottom: 3rem;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;

		.item {
		  margin: 0.5rem;
		  width: 90px;
		  height: 90px;
		  display: flex;
		  flex-direction: column;
		  justify-content: center;
		  align-items: center;
		  padding: .25rem;
		  color: #fff;
		  line-height: 1;
		  border-radius: 1rem;

		  .text {
			  margin-top: 1rem;
			  font-size: 1.1rem;
			  font-weight: 700;
			}

			.count {
			  font-size: 2rem;
			}
		}
	}


  .ui.header {
    font-size: 2rem;
    margin: auto;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;

    &.sub {
			margin-top: 2rem;
	    font-weight: 500;
	    font-size: 1.6rem;
	    color: #cadcea;
	    text-transform: capitalize;
    }
	}

	.logo {
    margin-bottom: 2rem;
		
		img {
		  max-width: 300px;
		  height: auto;
		  width: 100%;
		}
	}

	.more {
    margin: 5rem auto 0;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    .content {
    	max-width: 800px;
    	font-size: 1.2rem;
	    line-height: 1.5;
	    color: #fff;
    }
	}
}

.center.aligned {
	text-align: center;
}

.left.aligned {
	text-align: left;
}

.right.aligned {
	text-align: right;
}


.buttons {
	&.spaced {
		display: flex;

		.button {
			flex: 1;
			
			&.basic {
				border: 1px solid #dddddd;
				border-radius: .25rem;
			}

			&:not(:last-child) {
				margin-right: .5rem;
			}
		}
	}
}

.display-flex {
	display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

.custom.divider {
    border-bottom: 5px solid #2185d0 !important;
    margin: 1rem -1rem;
}


.d-none {
	display: none !important;
}

.d-none {
	display: none !important;
}

.d-block {
	display: block !important;
}

.d-table {
	display: table !important;
}

.d-flex {
	display: flex !important;
}

.d-inline-block {
	display: inline-block;
}

[v-cloak] { 
	display:none; 
}

[vhidden] {
	visibility: hidden;
}

.d-flex {
	display: flex;
}

.borderless {
	border: none !important;
}

.shadowless {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}


.radiusless {
	border-radius: 0 !important;
}

.w-25 {
	width: 25% !important;
}

.w-50 {
	width: 50% !important;
}

.w-75 {
	width: 75% !important;
}

.w-100 {
	width: 100% !important;
}

.capitalize {
	text-transform: capitalize !important;
}

img {
	max-width: 100%;
}

.divider.fluid {
	margin-left: -1rem !important;
	margin-right: -1rem !important;
}

.ui.celled.grid>.column:not(.row), .ui.celled.grid>.row>.column, .ui.celled.grid>.row {
	@extend .shadowless;
}

.ui.card>:last-child, .ui.cards>.card>:last-child {
	border-radius: 0;
}

.ui.secondary.menu .active.item {
	background: transparent;
	font-weight: 600;
}


span.tag {
	color: #fff;
    display: block;

	&.red {
		background: #ff3e4d!important;
		color: #fff!important;
	}
	&.yellow {
		background: linear-gradient(to right, #ffd366, #ffc73e, #ffb500) !important;
		background: #ffc12a;
		color: #000;
	}
	&.blue {
		background: #00a5ef !important;
		color: #fff !important;
	}
	&.teal {
		background: #009688 !important;
		color: #fff !important;
	}
	&.green {
		background: #00ad50 !important;
		color: #fff !important;
	}
}


.button {
	&.silver {
		background: silver;
    	color: #fff;

    	&:hover, &:active, &:focus {
    		color: #fff;
    		font-weight: bold;
    	}
	}

	&.tumblr {
		background: #44546A;
		color: #fff;

		&:hover {
			background: #394659;
			color: #fff;
		}
	}

	&.white {
		background: #fff !important;
		color: #000 !important;
		font-weight: normal !important;

		&.basic {
			border: 1px solid silver !important;
		}

		&:hover {
			font-weight: bold !important;
		}
	}
}


.label {
	&.white {
	    background: #fff !important;
	    color: #909090 !important;
	}

	&.silver {
	    background: silver !important;
	    color: #fff !important;
	}
}

.right.floated {
	float: right;
}

.dimmer.modals {
	overflow-y: auto;
}

.player {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  
  .controls {
  	height: 55px;
  	width: 100%;
  	position: absolute;
	  bottom: 0;
	  left: 0;
  	backdrop-filter: blur(15px);
	  align-items: center;
	  justify-content: center;
	  display: flex;
	  padding: .5rem 1rem;
  	background: rgb(35 35 35 / 9%);
  	z-index: 2;
  }

  .play, .stop {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    min-height: 30px;
    cursor: pointer;

  	img {
  		width: 100%;
  		filter: invert(1);
  	}
  }

  .play {
  	margin-right: .5rem;

  	&.playing {
  		img {
  			filter: invert(1) drop-shadow(3px 3px 10px #232323);
  		}
  	}
  }

  .stop {
  	margin-left: .5rem;
  }

  .wave {
    -webkit-mask-image: url(/assets/images/waves.svg);
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    height: 40px;
    width: 100%;
    position: relative;
    background-color: rgb(255 255 255);

    span {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 0%;
      background: #ff9e22;
    }
  }
}

.video-player {
	.ui.dimmer {
		height: calc(100% - 55px);
	}

	.player {
		position: relative;
    background: #252525 !important;
		padding-bottom: 55px;

    .controls {
		  background: #232323;
		}
	}

	.content {
		padding: 0 !important;
		background: transparent !important;
	}
}

#body {
  margin: auto;
  background: white;
  max-width: 100%;

  .ui.processing-transaction.dimmer {
  	position: fixed;
  	height: 100%;
  	width: 100%;
  }

	.card {
		@extend .shadowless;
		position: relative !important;
		
		&.product {
			.content {
				&:after {
					display: none;
				}
			}

			.image {
				background: transparent;
				position: relative;
				max-height: 295px;
				overflow: hidden;
				border-radius: 0.75rem !important;
				height: 295px;

				img.cover {
					z-index: 1;
					border-radius: .75rem .75rem 0 0;
					position: absolute;
					min-height: 100%;
					width: 100%;
					transition: top 1s ease 0s;
					top: 0;
				}

				.price {
					position: absolute;
					right: 0;
					top: 0;
					font-weight: 700;
					padding: 0.5rem 0.75rem 0.25rem;
					border-radius: 0 0 0 1rem;
					background: rgb(88 88 88 / 32%);
					-webkit-backdrop-filter: blur(3px);
					backdrop-filter: blur(3px);
					color: #fff;
					z-index: 2;
					font-size: 0.9rem;
				}

				.category {
			    position: absolute;
			    bottom: 0;
			    left: 0;
			    padding: 0.5rem 0.75rem 0.25rem;
			    background: rgb(88 88 88 / 32%);
			    border-radius: 0 1rem 0 0;
			    font-weight: 600;
			    color: #fff;
			    -webkit-backdrop-filter: blur(3px);
			    backdrop-filter: blur(3px);
			    z-index: 2;
			    max-width: 150px;
			    white-space: nowrap;
			    text-overflow: ellipsis;
			    overflow: hidden;
				}

				&:hover {
					.item-action {
					  width: 100%;

					  span {
						  visibility: visible;
						}
					}

					.video {
					  display: flex;
					}
				}

				.badge {
			    position: absolute;
			    left: 0;
			    top: 0.75rem;
			    padding: 0.5rem;
			    background: #ffffff00;
			    border-radius: 0 1rem 1rem 0;
			    z-index: 2;
			    -webkit-backdrop-filter: blur(10px);
			    backdrop-filter: blur(10px);
			    height: 32px;
			    display: flex;
			    width: 40px;
			    justify-content: center;
					align-items: center;

				  img {
				  	width: 20px;
				  	height: 20px;
				  }
				}

				.item-action {
				  position: absolute;
				  width: 0;
				  height: 100%;
				  left: 0;
				  top: 0;
				  display: flex;
				  justify-content: center;
				  align-items: flex-end;
				  transition: width .5s;
				  padding-bottom: 20%;

				  span {
					  position: relative;
					  z-index: 2;
					  cursor: pointer;
					  padding: .75rem 1rem;
					  font-size: 1.1rem;
					  font-weight: 600;
					  background: #ffffff6b;
					  border-radius: 1rem;
					  color: #000;
					  backdrop-filter: blur(3px);
					  transition: backdrop-filter .5s, background .5s;
					  visibility: hidden;
					  white-space: nowrap;

					  &:hover {
						  backdrop-filter: blur(0px);
						  background: #fff;
						}
					}
				}
			}

			* {
			  border: none;
			}

	    .title {
			  font-size: 1.1rem;
			  line-height: 1.5;
			  text-align: center;
			  font-weight: 600;
			  color: #333333;
			  display: flex;
			  justify-content: center;
			}

			&.audio {
				.controls {
					background: rgba(35, 35, 35, 0.09);
				}
			}

			&.video {
				.video {
			    background: rgb(0 0 0 / 6%);
			    position: absolute;
			    top: 0;
			    left: 0;
			    height: 100%;
			    width: 100%;
			    align-items: center;
			    justify-content: center;
			    z-index: 1;
			    backdrop-filter: blur(1px);
			    display: none;
			    cursor: pointer;
			
			    img {
				    width: 80px;
				    filter: invert(1);
					}
				}
			}

			&:nth-child(even) {
				.wave span {
					background: #00e1ff;
				}
			}
		}
	}

	.cards.products {
		&.four .card .image {
			height: 295px;
			max-height: 295px;
		}

		&.five .card .image {
			height: 210px;
			max-height: 210px;
		}
	}
}


#app {
	display: flex;
	min-height: 100vh;

	> section {
		flex: 1;
		overflow: hidden visible;
	}
}


#left-section {
	max-width: 340px;
	width: 100%;
	box-shadow: 2px 0 20px 0px rgb(0 0 0 / 5%);
	position: relative;

	&:before {
    position: absolute;
    left: 0;
    width: 70px;
    height: 100%;
    top: 0;
    background: aliceblue;
    content: '';
	}

  .header.logo {
		height: 80px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 70px;

    img {
		  max-width: 180px;
		  width: 100%;
		}
	}

	> .menu {
    padding: 0;
    height: calc(100vh - 80px);
    position: relative;

    .main {
		  padding: 1rem 0;
		  height: calc(100vh - 250px);
		  overflow: hidden;

		  &::-webkit-scrollbar {
			  width: 5px;
			  height: 5px;
			}

			&:hover {
				overflow: auto;
			}

			&::-webkit-scrollbar-track {
			  background: #EAEAEA;
			}

			&::-webkit-scrollbar-thumb {
			  background: #BBD8CB;

			  &:hover {
				  background: #556E7A;
				}
			}
		}

    .item {
	    display: block;
	    font-size: 1.15rem;
	    font-weight: 500;
	    color: #000;
	    padding: 0;
	    cursor: pointer;
	    position: relative;

	    &.list:not(.parent) {
	    	display: none;
		    width: calc(100% - 70px);
		    margin-left: auto;
		    background: #fefcff;
		    border-top: 1px solid #e4e4e4;
		    border-bottom: 1px solid #e4e4e4;

			  &.active {
			  	display: block;
			  }

			  .item {
					padding: 17.55px 1.25rem;
					min-height: 55px;
					display: block;
					align-items: center;
					width: 100%;

				  &:hover {
					  font-weight: 600;

					  &:first-letter {
						  color: #ff5343;
						  font-weight: 700;
						}
					}
				}
			}

	    &.input {
			  border: none;
			  display: flex;
			  padding: 1rem !important;
			  border-radius: 0;
			  background: #fff;
			  height: 70px !important;

			  input {
				  border-radius: 0.75rem 0 0 0.75rem;
				}

				button {
				  border-radius: 0 0.75rem 0.75rem 0 !important;
				}
			}

			&:hover:not(.input) {
				.image img {
					transform: rotate(-360deg);
				}

				.text {
					font-weight: 600;
				}
			}

	    > div {
			  display: flex;
			  align-items: center;
			  width: 100%;

			  > span {
				  flex: 1;
				  height: 55px;
				  display: flex;
				  align-items: center;
				  text-transform: capitalize;
				}
			}

		  .image {
			  width: 100%;
			  padding: 0.8rem;
			  max-width: 70px;
			  background: transparent;
			  min-width: 70px;
			  height: 55px;
			  align-items: center;
			  justify-content: center;

			  img {
			  	transition: transform 1s;
				  width: 23px;
				}
			}

			.text {
			  padding: 0.8rem 1.25rem;
			}
		}

		.footer {
	    height: 100px;
	    background: #fff;
	    color: #000;

			.social {
			  display: flex;
			  padding: 1rem 1.5rem;
			  align-items: center;
			  justify-content: center;
			  height: 65px;

			  a {
				  flex: 1;
				  display: flex;
				  align-items: center;
				  justify-content: center;

				  img {
					  width: 40px;
						border-radius: 500px;
					}
				}
			}

			.copyright {
			  height: 35px;
			  display: flex;
			  align-items: center;
			  justify-content: center;
			  width: 100%;
			  font-weight: 600;
			}
		}
	}
}


#right-section {
	overflow: auto;
	max-width: 1580px;
	height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;

	#body > .selection {
    padding: 2rem;

    > .header {
    	text-transform: capitalize;
    	line-height: 1.5;
			font-size: 1.8rem;
			margin-bottom: 1.5rem;
			font-weight: 600;
			background: -webkit-linear-gradient(56deg, #003d50, #00c4ff 50%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			display: table;

			&:hover {
				/* background: -webkit-linear-gradient(56deg, #00c2ff, #a200ff 90%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent; */
				font-weight: 700;
			}
		}

		&.popular {
			> .header {
				background: -webkit-linear-gradient(56deg, #00fdff, #ff6c00 90%);
				-webkit-background-clip: text;
    		-webkit-text-fill-color: transparent;

    		&:hover {
    			/* background: -webkit-linear-gradient(56deg, #00fdff, #ff5f5f 90%);
					-webkit-background-clip: text;
	    		-webkit-text-fill-color: transparent;    */
	    		font-weight: 700; 			
    		}
			}
		}
	}
}


#top-menu {
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  //position: fixed;
  top: 0;
  left: 300px;
  background: #fff;
  max-width: 1580px;
  width: 100%;
  z-index: 999;
  border-bottom: 1px solid whitesmoke;

  .ui.menu {
    font-size: 1.1rem;
    height: 80px;
    width: 100%;
    padding: 0 1.5rem;
    margin: 0;

    .item.logo {
	    width: 180px;
	    display: none;
	    margin-left: 0;
	    margin-right: 1rem;

      img {
      	height: auto;
      	width: 100%;
      }
    }

    > .item {
      font-weight: 600;
      height: 45px;
      background: transparent;
      color: #000;

      &.search {
        margin: 0 auto 0 0;
        max-width: 350px;
    		width: 100%;

        form {
			    height: 45px;
			  }

        input {
          border-radius: .75rem;
        }
      }

      &:not(.search):not(.logo) {
        background: ghostwhite;
        border-radius: .75rem;

        &:hover {
				  background: rgb(224 224 233 / 46%);
				}
      }

      &.notifications, &.cart {
				background: transparent !important;
				position: relative;
				border: none !important;
				padding-right: 0.25rem;
				padding-left: 0.25rem;

				.default {
				  position: relative;
				  display: block;
				  padding-right: 2rem;

				  img {
						display: inline-block;
						vertical-align: top;
						width: auto;
						margin-top: -0.5em;
						margin-bottom: -0.5em;
						max-height: 2em;
				  }
				}

				span {
				  position: absolute;
				  right: 0;
				}

				.menu {
					max-width: 320px;
					min-width: 320px;
					border-radius: 0.75rem;
					border: none;

					.container {
					  max-height: 245px;
					  overflow: auto;

					  &::-webkit-scrollbar {
						  width: 5px;
						  height: 5px;
						}

						&:hover {
							overflow: auto;
						}

						&::-webkit-scrollbar-track {
						  background: #EAEAEA;
						}

						&::-webkit-scrollbar-thumb {
						  background: #b061ff;

						  &:hover {
							  background: #582a86;
							}
						}
					}

	      	.item {
						display: flex;
						padding: 0 !important;
						align-items: flex-start;
						overflow: hidden;
						cursor: default;

						&:hover {
							background: transparent !important;
						}

						.image {
							width: 60px;
							height: 60px;
							min-width: 60px;
							min-height: 60px;
							margin: 0.75rem;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 0.75rem;
							background-position: center;
							background-size: cover;
						}

	      		.content {
							padding: 0.75rem 0.75rem 0.75rem 0;
							line-height: 1.5;
							overflow: hidden;
							position: relative;
							flex: 1;

							.name {
							  font-weight: 500;
							  margin-bottom: 0.5rem;
							  display: block;
							  text-overflow: ellipsis;
							  white-space: nowrap;
							  overflow: hidden;

							  &:hover {
								  color: #672da6;
								  font-weight: 600;
								}
							}

							.date {
						    font-size: 1rem;
						    font-weight: 600;
						    color: #000;
						  }

							.price {
							  font-size: 1.2rem;
							  font-weight: 600;
							}

							.trash {
							  position: absolute;
							  right: 0.5rem;
							  bottom: 0.5rem;
							  cursor: pointer;

							  &:hover {
								  font-weight: 600;
								}
							}
						}

						&.all {
					    padding: 1rem !important;
					    display: block;
					    text-align: center;
					    font-size: 1.2rem !important;
					    font-weight: 600 !important;
					    background: #5d5dff !important;
					    color: #fff !important;
					    line-height: 1.5;
					    border-radius: 0 0 0.75rem 0.75rem;
					    cursor: pointer;

					    &:hover {
					    	background: #4b4bd1 !important;
					    }
						}
	      	}
	      }
      }

      &.notifications {
      	margin-left: auto;
      }

      &.mobile-menu-toggler {
		    display: none;
		    cursor: pointer;
		    border-radius: 100px !important;
		    margin-left: 1rem;
		    background: linear-gradient(45deg, #ffb13c, #ff877b, #ffb13c) !important;
		    height: 40px;
		    width: 40px;
		    padding: 0;
		    align-items: center;
		    justify-content: center;

		    i {
					font-size: 1.7rem;
					color: #fff7f7;
		    }

		    &:hover {
		    	background: linear-gradient(45deg, #ffa115, #ff6758, #ffa115) !important;
		    }
      }

      &.user {
      	padding: 0;
			  position: relative;
		    box-shadow: none;
		    background: transparent !important;

		    .menu {
				  max-height: 270px;
				  overflow: auto;
				  border-radius: 0.75rem;
				  box-shadow: none;
				 	min-width: max-content;

				  .item {
				    font-weight: 500 !important;
				    color: #000 !important;
				    padding: 0.75rem 1rem !important;
				    display: flex;
				    align-items: center;
				    border-radius: 0;
				    font-size: 1rem !important;

				    img {
						  width: 20px;
						  position: relative;
						  top: -2px;
						  margin-right: 0.5rem;
						}

					  &.earnings {
						  padding: 1.25rem !important;
						  background: #7238ac !important;
						  color: #fff !important;
						  font-weight: 600 !important;
						}

						&:not(:last-child) {
						  border-bottom: 1px solid #e3e3e3;
						}
					}
				}

			  .default {
				  display: flex;
				  align-items: center;
				  justify-content: center;

					img {
						margin: 0;
						height: 40px;
						width: 40px;
						min-width: 40px;
						min-height: 40px;
						border-radius: 100px;
					}
				}

				&:hover {
					.text span {
						background: rgb(224 224 233 / 46%);
					}
				}
			}
    }
  }
}


#categories {
  padding: 2rem 0;
  background: #fff;

  .wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 2rem;
    overflow: hidden;
  }

  .item {
    height: 80px;
    min-height: 80px;
    width: 150px;
    min-width: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 0.5rem;
    border-radius: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.5;
    font-weight: 600;
    position: relative;
		font-size: 1.1rem;

		&:empty {
		  background: linear-gradient(45deg, #f7f7f7, whitesmoke, #f7f7f7);
		}

    &:last-child {
		  margin-right: 0;
		}

		&:first-child {
		  margin-left: 0;
		}

    div {
    	transition: width .25s, height .25s;
      display: flex;
      margin: auto;
      padding: .5rem;
      background: rgb(36 55 63 / 40%);
      color: #fff;
      border-radius: .75rem;
      backdrop-filter: blur(3px);
      height: calc(100% - 1.5rem);
      justify-content: center;
      align-items: center;
      width: calc(100% - 1.5rem);
			pointer-events: none;
			word-break: break-word;
    	text-transform: capitalize;
    }

    &:hover div {
    	width: calc(100%);
    	height: calc(100%);	
    }
  }
}


#top-panel {
	min-height: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
  margin: 0;
  padding: 2rem;

	.cover {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: initial;
    background-repeat: no-repeat;

    .refresh {
		  position: absolute;
		  top: 1rem;
		  left: 1rem;
		  background: #fff;
		  padding: 0.75rem;
		  line-height: 1;
		  font-weight: 700;
		  border-radius: 0.75rem;
		}
	}

  .header {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 3rem;
    max-width: 650px;
    line-height: 1.8;
    text-align: center;
    padding: 1.5rem;
    border-radius: 0.75rem;
    background: rgb(255 255 255 / 14%);
    color: #fff;
    text-transform: capitalize;
    z-index: 1;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: 1px solid #ffffff45;
	}

	form {
	  max-width: 500px;
	  width: 100%;

	  * {
		  font-size: 1.2rem !important;
		}

		button {
		  border-radius: 0 0.75rem 0.75rem 0;
		  border: none;
		}

		input {
			border-radius: 0.75rem 0 0 0.75rem;
			border: none;
		}

	  .input {
	    height: 55px !important;
	    border: none;
	    border-radius: 0.75rem;
	    background: transparent;
		}
	}
}


#footer {
	background: #fff;
	position: relative;

	&:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-image: url("/storage/images/footer_cover.jpg");
    opacity: .5;
	}

	.counters.cards {
		.card {
			flex: 1;
			margin: .75rem;
			border: none;
			box-shadow: none;
			border-radius: .75rem;

			.header {
		    font-size: 1.4rem;
		    text-align: center;
		    font-weight: 600;
		    line-height: 1.5;
		    padding-bottom: 1rem;
		    text-decoration: capitalize;
			}

			.count {
				font-size: 1.5rem;
		    text-align: center;
		    display: flex;
		    align-items: center;
		    background: linear-gradient(138deg, transparent, #fcfcff);

		    img {
				  width: 80px;
				  margin-right: 1rem;
				}

				span {
				  flex: 1;
				  line-height: 1.5;
				  font-weight: 500;
				}
			}
		}

		margin: 0 0 1rem 0;
		padding: 1.5rem 1.25rem;
		position: relative;
		background: transparent;
	}

	.newsletter {
		position: relative;
    font-size: 1.2rem;
    padding: 2rem;
    margin: -1rem 0;
    text-align: center;
    background: radial-gradient(transparent, rgb(247 247 247));

	  .wrapper {
	  	display: flex;
	    align-items: center;
	    justify-content: center;

		  > div {
			  margin: 1rem 0;
			}

		  .header {
		    margin-right: 1rem;
		    font-size: 1.5rem;
		    font-weight: 600;
		    max-width: 180px;
		    line-height: 1.4;
		    text-transform: capitalize;
		    color: #000;
			}

			input {
			  border-radius: 1rem 0 0 1rem;
			  padding-left: 2rem;
			  padding-right: 2rem;
				border: none;
				outline: none;
			}

			button {
				font-size: 1.2rem;
				font-weight: 600;
				border-radius: 0 1rem 1rem 0;
				background: #8547c3;
				color: #fff;

				&:hover {
					background: #653099;
				}
			}

			.input {
			  height: 60px;
			  max-width: 500px;
			  width: 100%;
			}
	  }
	}

	.data {
    max-width: 700px;
    margin: 3rem auto 2rem;
    padding: 2rem 1rem;
    text-align: center;
    position: relative;
    background: rgb(255 255 255 / 83%);
    border-radius: 0.75rem;
    backdrop-filter: blur(3px);

    .header {
		  font-size: 2.5rem;
		  font-weight: 700;
		  margin-bottom: 1.5rem;
		  color: #000000;

		  &:first-letter {
			  color: #ffe061;
			}
		}

		.description {
		  font-size: 1.3rem;
		  line-height: 1.5;
		  color: #000;
		  font-weight: 500;
		}
	}

	.ui.menu {
		position: relative;
	  padding: 0 2rem 2rem;
	  justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 0;

	   > .item {
		  background: #ffffff;
		  font-size: 1.1rem;
		  color: #000;
		  font-weight: 500;
		  border-radius: 0.75rem;
		  border: 1px solid #afafaf;
		  margin: 0.5rem;
		}

		.dropdown .menu {
		    box-shadow: none !important;
		    border: none !important;
		    border-radius: .75rem !important;
		    overflow: hidden;

		    .wrapper {
				  max-height: 120px;
				  overflow: auto;
				}

		    .header {
		        margin: 0 !important;
		        padding: 1rem !important;
		        background: #ffff51;
		        color: #000 !important;
		        font-size: 1rem !important;
		    }

		    .item:not(.header) {
		        text-transform: capitalize;
		        width: 100%;
		        margin: 0 !important;
		        border-radius: 0 !important;

		        &:hover {
		            background: transparent !important;
		            font-weight: 600;
		        }
		    }
		}
	}
}


.ribbon1 {
  position: absolute;
  top: -6.1px;
  left: 2rem;
}
.ribbon1 div  {
  position: relative;
  display: block;
  text-align: center;
  background: #F8463F;
  padding: .75rem 1rem .5rem;
  border-radius: 0 8px 8px 8px;
}
.ribbon1 div span:first-child {
	position: absolute;
  content: "";
	height: 10px;
	width: 10px;
	left: -10px;
	top: 0;
	background: #F8463F;
	border-radius: 8px 0 0 0;
}
.ribbon1 div span:last-child {
	position: absolute;
  content: "";
  height: 10px;
  width: 12px;
  left: -11px;
  top: 0;
  border-radius: 10px 10px 0 0;
  background: #C02031;
  filter: brightness(0.85);
}


#credits-checkout-form {
	border-radius: .75rem;
	overflow: hidden;

	> .header {
		background: #ff666c;
		height: 70px;
		display: flex;
		align-items: center;
		color: #fff;
		font-size: 1.8rem;
		line-height: 1.5;
		font-weight: 600;
	}

	> .actions {
		background: aliceblue;
	}

	> div {
		border: none;
	}

	tr.discount * {
	  color: #00ae7b;
	  font-weight: 600;
	}

	tr.total * {
    font-weight: 600;
    background: #fff;
	}

	thead * {
    background: aliceblue;
    border: none;
	}

	tfoot * {
    background: #fff;
    font-weight: 600;
	}
}


#checkout-page {
	min-height: 400px;

  > .header {
		font-size: 1.8rem;
		line-height: 1.5;
		font-weight: 700;
		padding: 2rem 3rem;
		background: linear-gradient(90deg, #ffffff, #f8f8f8, #f9f9f9);
		position: relative;
		z-index: 1;

		> div:not(.subheader) {
			background: -webkit-linear-gradient(56deg, #ff7474, #ffad59 20%), -webkit-linear-gradient(56deg, #ff7474, #ffad59 20%), -webkit-linear-gradient(56deg, #ff7474, #ffad59 20%);
	    -webkit-background-clip: text;
	    -webkit-text-fill-color: transparent;
	    font-weight: 700;
	    font-size: 2rem;
		}
  	
  	.subheader {
	    margin-top: 0.5rem;
	    font-size: 1.5rem;
	    color: #4a4a4a;
	    font-weight: 700;
	  }
  }

  > .container {
    display: flex;
    margin: auto;
    min-height: 600px;

    > div {
			padding: 3rem;
			flex: 1;
    }

    .left-side {
    	> .header {
    		display: none;
    	}

    	.items {
    		display: flex;
		    flex-wrap: wrap;
		    margin: -0.5rem;
    	}

			.item {
				&.product {
					display: flex;
					padding: 1rem;
					background: #fefcff;
					border-radius: 0.75rem;
					position: relative;
					overflow: hidden;
					width: calc(50% - 1rem);
					margin: 0.5rem;

					.image {
						width: 120px;
						height: 120px;
						margin-right: 1rem;
						border-radius: .75rem;
						background-position: center;
						background-size: cover;
						display: flex;
					}

					.content {
						flex: 1;
						display: flex;
						flex-direction: column;
						font-size: 1.1rem;
						padding-top: 0.5rem;

						*:not(:last-child) {
						  margin-bottom: 0.5rem;
						}

						.name {
							font-weight: 500;
						}

						.close {
					    position: absolute;
					    right: 0;
					    bottom: 0;
					    padding: 1rem;
					    width: 45px;
					    height: 45px;
					    display: flex;
					    align-items: center;
					    justify-content: center;
					    background: #ff4f2f;
					    font-size: 1.2rem;
					    font-weight: 400;
					    color: #fff;
					    border-radius: 2rem 0 0 0;
						}

						.license {
							font-weight: 500;
					    text-transform: capitalize;
					    margin: 0.5rem 0;
					    background: -webkit-linear-gradient(35deg, #ff6135, #009e7a 46%);
					    -webkit-background-clip: text;
					    -webkit-text-fill-color: transparent;
						}

						.price {
							font-weight: 500;
							margin-top: .5rem;
							font-weight: 600;
						}
					}
				}

				&.subscription {
				  max-width: 320px;
				  width: 100%;

				  .header {
				    display: flex;
				    font-size: 1.3rem;
				    flex-wrap: wrap;
				    justify-content: space-between;
				    padding: 1.5rem 2rem;
				    font-weight: 600;
				    text-transform: uppercase;
				    background: #ac62db;
				    border-radius: 0.75rem 0.75rem 0 0;
				    color: #fff;
					}

					.description {
					  padding: 2rem;
					  background: #f6f6f6;
					  border-radius: 0 0 0.75rem 0.75rem;

					  > div {
						  display: flex;
						  font-weight: 500;
						  color: #000;
						  align-items: center;
						  text-align: left;
						  text-transform: capitalize;
						  line-height: 1.5;
						  margin-left: auto;
						  margin-right: auto;
						  font-size: 1.2rem;

						  &:not(:last-child) {
							  margin-bottom: 0.75rem;
							}

							img {
							  width: 25px;
							  margin-right: 0.5rem;
							  position: relative;
							  top: -3px;
							}
						}

						.item {
							display: flex;
							border-radius: 0.75rem;
							align-items: center;
							font-weight: 500;
							font-size: 1.1rem;

							&:not(:last-child) {
							  margin-bottom: .75rem;
							}

							.icon {
						    width: 30px;
						    height: 30px;
						    margin-right: 1rem;
						    padding: 0.5rem;
						    background: #ff7272;
						    border-radius: 500px;
						    display: flex;

						    img {
						    	width: 100%;
						    	top: 0;
						    }

								&:not(.included) {
								  width: 29px;
								  height: 29px;
								  background: #ededed;
								  padding: 0.6rem;

								  img {
								  	display: none;
								  }
								}

								&.included img {
								  filter: invert(1);
								}
					    }
						}
					}
				}
			}
		}

		.right-side {
	    padding: 3rem;
	    max-width: 400px;
	    width: 100%;
	    position: relative;

	    &:before {
			  content: '';
			  position: absolute;
			  top: 0;
			  left: 0;
			  width: 100%;
			  height: 100%;
			  box-shadow: -5px 0px 14px 7px rgb(128 128 128 / 5%);
			}

			.summary {
		    font-size: 1.1rem;
		    line-height: 1.5;
		    color: #000;
		    margin-bottom: 3rem;
		
				>.header {
					font-size: 1.4rem;
			    font-weight: 600;
			    margin-bottom: 1rem;
				}
		
				>.content {
					padding-left: 1.5rem;
					position: relative;

					&:before {
					  content: '';
					  position: absolute;
					  left: 0;
					  top: 0;
					  height: 100%;
					  width: 0.2rem;
					  background: linear-gradient(45deg, #00c5b5, #00c5b512, #ffffff);
					}

					> div {
						padding: .75rem 0;
						font-weight: 500;
						font-size: 1.2rem;

						&:first-child {
							padding-top: 0;
						}

						&:last-child {
						  padding-bottom: 0;
						}

						&.total {
						  font-weight: 600;
						  color: #ff662d;
						}
					}
				}
			}

			.methods {
		    font-size: 1.2rem;
		    font-weight: 600;
		    margin-bottom: 2rem;
		    position: relative;

			  >.header {
					font-size: 1.4rem;
			    font-weight: 600;
			    margin-bottom: 1rem;
				}

			  .ui.dropdown {
				  border-radius: 0.75rem !important;
				  box-shadow: none;

				  .text {
					  align-items: center;
					  display: flex;

					  img {
						  border-radius: 100px;
						  max-width: 34px;
						  width: 100%;
						  height: 100%;
						  max-height: 34px;
						}
					}

				  .menu {
				    box-shadow: none;
				    border-radius: 0.75rem;
				    margin-top: 1rem;
				    margin-bottom: 1rem;
				    border: none;
				    background: #fff;
				    overflow: hidden;
				    max-height: 300px;

					  &::-webkit-scrollbar {
						  width: 5px;
						  height: 5px;
						}

						&:hover {
							overflow: auto;
						}

						&::-webkit-scrollbar-track {
						  background: #EAEAEA;
						}

						&::-webkit-scrollbar-thumb {
						  background: #b061ff;

						  &:hover {
							  background: #582a86;
							}
						}

					  &:hover {
					  	overflow: auto;
					  }
					}

				  .item {
				    display: flex;
				    align-items: center;
				    padding: 1.5rem 1.5rem 1rem!important;

				    &:not(:last-child) {
						  border-bottom: 1px solid #dddddd;
						}

				    &:hover, &.active, &.selected {
							background: #fff;
							font-weight: 600;
						}

						.content {
							min-width: 0;
						}

					  img {
					    width: 50px;
					    height: 50px;
					    min-height: 50px;
					    min-width: 50px;
					    margin-right: 1rem;
							border-radius: 100px;
						}

						.name {
					    font-size: 1.3rem;
					    text-transform: capitalize;
					    font-weight: 600;
					    line-height: 1;
						}

						.description {
					    margin-top: 1rem;
					    line-height: 1.5;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}
			}

			.coupon {
		    font-size: 1.2rem;
		    font-weight: 600;
		    position: relative;

			  .message {
			  	font-weight: 500;
			    padding: 1rem;
			    margin-top: 1rem;
			    color: #000;
			    background: #fff;
			    border-radius: 0.75rem;

			  	&.negative {
					  color: #fff;
					  background: #ff7878;
					}

					&.positive {
						background: #00af84;
						color: #fff;
					}
			  }

			  >.header {
					font-size: 1.4rem;
			    font-weight: 600;
			    margin-bottom: 1rem;
				}

				.button {
					border-radius: 0 0.75rem 0.75rem 0;
					border: 1px solid rgba(34,36,38,.15);
					background: #fff;
					color: #000;
					font-weight: 600;

					&:hover {
						font-weight: 700;
					}
				}

				input {
					border-radius: .75rem 0 0 .75rem;
				}
			}

			.checkout {
				margin-top: 2rem;
				position: relative;

				.terms {
					margin-bottom: 1rem;
					a {
						font-weight: 600;
					}
				}

				.button {
				  border-radius: .75rem;
				  font-weight: 600;
				  font-size: 1.2rem;
				  display: flex;
				  align-items: center;
				  justify-content: center;
				  padding: 1.5rem 1.5rem 1rem;
				  background: #556371;
				  color: #fff;
				  text-transform: uppercase;

				  &:hover {
					  font-weight: 700;
					}
				}
			}
		}
	}
}


#form-checkout {
	position: relative;

	label {
	  margin-left: 1rem;
	}

	input {
		border-radius: .75rem;
	}

	.form-fields {
		flex-wrap: wrap;
		margin: 0 -.5rem;

		&:empty {
			display: none;
		}

		.field {
		  margin-bottom: 1rem;

		  &.fluid {
		  	width: 100%;
		  }

		  &.custom_amount {
			  width: calc(100% + 3rem);
			  margin: 0 -1.5rem 2rem;
			  padding: 2rem;
			  background: #fff;
			  border-top: 2px dashed #e5e5e5;
			  border-bottom: 2px dashed #e5e5e5;

			  label {
			  	margin-left: 0;
			  	font-size: 1.4rem;
			    font-weight: 600;
			    margin-bottom: 1rem;
			  }
			}
		}

		.guest-email {
			flex: 1 !important;
	    min-width: 100%;
	    margin-bottom: 2rem;
		}

		.notes {
	    flex: 1 !important;
	    margin-bottom: 2rem !important;
	    min-width: 100%;
			
			label {
		  	margin-left: 0;
		  	font-size: 1.4rem;
		    font-weight: 600;
		    margin-bottom: 1rem;
		  }

		  textarea {
			  border-radius: .75rem !important;
			  color: #264d97;
			}
		}
	}
}


#checkout-response {
  width: 100%;
  background: linear-gradient(45deg, #f3f3f3, #f7f7f7, #fbfbfb);
  padding: 0 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	margin-top: -2rem;

	& > .heading {
		text-align: center;
		line-height: 1.5;
		margin: 0 -2rem;
		padding: 2rem;
		position: relative;
		z-index: 9;
		width: 100%;
	
		.title {
	    font-size: 2rem;
	    font-weight: 700;
	    margin-bottom: 1rem;
	    color: #424242;
		}

		.subheading {
	    font-size: 1.8rem;
	    font-weight: 800;
	    color: #ff737d;
	    background: -webkit-linear-gradient(42deg, #ff737d 50%, #4e4e4e 20%);
	    -webkit-background-clip: text;
	    -webkit-text-fill-color: transparent;
		}
	}

	.columns {
		max-width: 900px;
		margin: 0 auto;
		display: flex;
		width: 100%;
	  padding: 0 2rem;
    justify-content: center;

		& > div {
			padding: 2rem;
			font-size: 1.1rem;
			background: #fff;
			border-radius: 1rem;
			flex: 1;
			overflow: hidden;
			max-width: 400px;

			&.left {
				margin-right: 2rem;
	
				.note {
					font-size: 1rem;
					margin: 1rem 0;
					padding: 1rem;
					background: slategrey;
					border-radius: 0.75rem;
					color: #fff;
					line-height: 1.5;
	
					span {
						font-weight: 600;
						color: #ffffff;
					}
				}
			}
	
			&.right {	
				& > .heading {
					border-left: 0.25rem solid #ffcc75;
				}
	
				.note {
					font-size: 1rem;
					text-align: center;
					margin: 1rem 0 0;
					padding: 1.5rem 2rem;
					color: #000;
					font-weight: 600;
					border-radius: 0.75rem;
					box-shadow: inset 0 0 11px -2px rgb(0 0 0 / 11%);
	
					span {
				    font-weight: 600;
				    color: #ff6d3d;
				    font-size: 1.1rem;
				    text-transform: lowercase;
					}
				}
			}
	
			& > .heading {
				font-size: 1.3rem;
				margin-bottom: 1rem;
				font-weight: 600;
				padding: 1rem 1.5rem;
				border-radius: 0.75rem;
				background: #f4f4f4;
				border-left: 0.25rem solid #9675ff;
				border-radius: 0 0.75rem 0.75rem 0;
			}
	
			.items {
				.item {
					display: flex;
					justify-content: space-between;
					padding: .5rem 0;
	
					.name {
						max-width: 300px;
						margin-right: auto;
					}
	
					&:not(:last-child) {
						border-bottom: 2px dotted #c4c4c4;
					}
	
					&.minus {
						color: #00baff;
						font-weight: 600;
					}
	
					&.total {
						font-weight: 600;
						color: #ff9250;
						font-size: 1.2rem;
					}
	
					.text {
						font-weight: 600;
						margin-left: 1rem;
	
						.download {
							text-decoration: none;
							padding: 0.5rem 0.75rem;
							display: table;
							line-height: 1.5;
							border-radius: 0.5rem;
							color: #000000;
							background: radial-gradient(#ffd944, #ffe3b6);
							font-size: 1rem;
	
							&:hover {
								background: #ffdc55;
							}
						}

						&.list {
							position: relative;

							&:hover {
								.menu {
									display: block;
								}
							}

							.download {
								cursor: pointer;
							}
							
							.menu {
								position: absolute;
								left: 0;
								background: #fff;
								border-radius: .5rem;
								top: auto;
								padding: .5rem 0;
								display: none;
								z-index: 1;

								.link {
									display: block;
									padding: .5rem .75rem;
									min-width: 100px;
									background: linear-gradient(315deg, #888888, #7e7e7e);
									color: #fff;
							
									&:first-child {
										border-radius: .5rem .5rem 0 0;
									}
							
									&:last-child {
										border-radius: 0 0 .5rem .5rem;
									}
							
									&:not(:last-child) {
										border-bottom: 1px solid #fff;
									}
							
									&:hover {
										background: linear-gradient(315deg, #5e5e5e, #7e7e7e);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.buttons {
	  display: flex;
	  align-items: center;
	  max-width: 800px;
	  margin: 1rem auto 0;
	  width: 100%;
	  justify-content: center;

	  a {
		  display: block;
		  text-decoration: none;
		  font-size: 1.2rem;
		  padding: 1rem 1rem;
		  color: #000;
		  font-family: system-ui;
		  background: #fff;
		  border-radius: 0.5rem;
		  text-align: center;
		  font-weight: 600;
		  flex: 1;

		  &.homepage {
			  margin-right: 2rem;
			  box-shadow: inset 4px 4px 0 0px #ffc253;
			  color: #000;
			}

			&.purchases {
		    box-shadow: inset -4px -4px 0 0px #ff7878;
		    color: #ff7878;
			}
		}

		&.guest {
			.homepage {
		  	margin-right: 0;
		  }
		}
	}

	&:not(.product) .columns {
		> .column.left {
	    margin: 0 auto;
		}

		> .column.right {
		  display: none;
		}
	}
}



#pricing {
	> .title {
		color: #000;
		margin: 0 auto;
		text-align: left;
		line-height: 1.5;
		padding: 2rem;
		border-bottom: 1px solid whitesmoke;

    .header {
			font-size: 1.8rem;
			line-height: 1.5;
			font-weight: 700;
			background: -webkit-linear-gradient(59deg, #12425d, #4fc0ff 21%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
    }

    .subheader {
	    font-size: 1.3rem;
	    line-height: 1.5;
	    margin-top: 0.75rem;
    }
  }

  > .cards {
		margin: auto;
		padding: 3.5rem 3rem;
		background: rgb(253 253 253);
    align-items: center;
		justify-content: center;
  }

	.card {
		box-shadow: 0 0 20px 10px rgb(0 0 0 / 3%) !important;
		border-radius: 4rem 4rem 1.5rem 1.5rem;
		margin-top: 3rem !important;
		margin-bottom: 3rem;

		* {
			color: #000 !important;
		}

		.content {
			border: none;

			&.header {
			  display: flex;
			  align-items: center;
			  padding: 3.5rem 2.5rem 2.5rem;
			  position: relative;

			  &:before {
			    content: "";
			    position: absolute;
			    top: 0;
			    width: 100%;
			    height: 20px;
			    left: 0;
			    -webkit-mask-image: url(/assets/images/pricing_card_mask.png);
			    -webkit-mask-size: cover;
			    -webkit-mask-position: bottom center;
			    background: #f3f3f3;
			    z-index: 1;
			    overflow: hidden;
			    max-height: 20px;
				}

			  .label {
			  	display: none;
			  }

			  .icon {
				  width: 60px;
				  height: 60px;
				  rotate: -20deg;
				}

				.description {
				  display: block;
				  margin-left: 1rem;

				  .title {
					  font-size: 1.6rem;
					  line-height: 1.5;
					  font-weight: 600;
					 	margin-bottom: 0.75rem !important;
					}

					.pricing {
					  display: flex;
					  align-items: baseline;

					  .price {
						  padding: 0;
						  margin: 0 0.5rem 0 0!important;
						  line-height: 1;
						  font-size: 1.8rem;
						  font-weight: 600;
						}

						.time {
						  font-weight: 500;
						}
					}
				}
			}

			&.body {
				padding: 0 3.5rem 2rem;

				.header {
			    font-weight: 600;
			    text-align: center;
			    padding-bottom: 1rem;
			    display: table;
			    margin: 1rem auto 0;
				}

				.item {
					display: flex;
					border-radius: 0.75rem;
					align-items: center;
					font-weight: 500;
					font-size: 1.1rem;

					&:not(:last-child) {
					  margin-bottom: .75rem;
					}

					.icon {
				    width: 30px;
				    height: 30px;
				    margin-right: 1rem;
				    padding: 0.5rem;
				    background: #00cdeb;
				    border-radius: 500px;
				    display: flex;

				    img {
				    	width: 100%;	
				    }

						&:not(.included) {
						  width: 29px;
						  height: 29px;
						  background: #ededed;
						  padding: 0.6rem;

						  img {
						  	display: none;
						  }
						}

						&.included img {
						  filter: invert(1);
						}
			    }
				}
			}

			&.footer {
				padding: 0 3rem 2rem;

				.button {
				  font-size: 1.2rem;
				  color: #000;
				  border-radius: 100px;
				  font-weight: 600;
				  background: #ffd53f;
				}
			}
		}

		&.popular {
			background: slateblue;

			* {
				color: #fff !important;
			}

			.label * {
			  color: #000 !important;
			}

			.header img {
			  filter: invert(1);
			}

			.content {
				&.header {
					.label {
				    position: absolute;
				    top: -2.25rem;
				    z-index: 1;
				    width: 100%;
				    display: flex;
				    justify-content: center;
				    left: 0;

					  > div {
							display: block;
							margin: auto;
							text-align: center;
							border-radius: 2rem 2rem 40% 40%;
							background: white;
							font-weight: 600;
							padding: 0.85rem 2rem 0.35rem;
							color: #000;
							border-bottom: 0.5rem solid #6a5acd;
							font-size: 1.5rem;

					    &:first-letter {
							  //color: #6a5acd;
							}
						}
					}
				}

				&.footer {
					.button {
						background: #fff;
						color: #000 !important;
					}
				}
			}
		}
	}
}


#items {
	padding: 2rem;
	min-height: 500px;

	.results {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .header {
			font-size: 1.8rem;
			background: -webkit-linear-gradient(56deg, #00fdff, #ff6c00 90%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			line-height: 1.5;
			font-weight: 700;
		}

		.remove {
		  margin-left: auto;
		  font-weight: 500;
		  font-size: 1.2rem;
		}
	}

	> .ui.menu.filter {
    width: 100%;
    background: #fafafb;
    border-radius: 0.75rem;
    height: 55px;
		overflow: hidden;
		
		> .item {
		  position: relative;
		  overflow: hidden;
		  border-radius: 0;
			padding: 1rem 1.5rem;
			color: #000;
		  font-size: 1.1rem;
		  font-weight: 500;
	  	position: relative;

		  &:not(:last-child) {
			  border-right: 5px solid #fff;
			}
		
		  &:hover, &.selected {
			  background: #ff5349;
			  font-weight: 600;
			  color: #fff;
			}
		}
	}
}


#item {
  .ui.breadcrumb {
  	padding: 2rem;
    width: 100%;
    background: #f8fcff;
    line-height: 1.5;

    * {
    	color: #000;
    }

    a {
			font-weight: 600;
		}

		.active.section {
		  font-weight: 600;
		  color: #009fff;
		}
  }

  .container {
    display: flex;
    font-size: 1.1rem;
    line-height: 1.3;
    color: #000;

  	> div {
      flex: 1;
    }

    .left-side {
    	> .ui.breadcrumb {
    		display: none;
    	}

    	.time-counter {
				display: none;
				padding: 1.5rem;
				font-size: 1.4rem;
				font-weight: 700;
				background: #fff;
				text-align: center;
				margin: 2rem auto 0;
				max-width: 1080px;
				border-radius: 1rem;

				.text {
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-image: linear-gradient(45deg, #006d85, #00d1ff);
				}

				.time {
					margin-left: .25rem;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-image: linear-gradient(301deg, #00d1ff, #097891);
				}
			}

    	> .header {
	    	display: flex;
				align-items: flex-start;

				.realtime-product-views {
			    margin: 1.5rem 0;
			    padding: 1rem 0.75rem;
			    background: #f0f3f7;
			    border-radius: 0.75rem;
			    font-weight: 500;
			    box-shadow: 0 0 20px 10px #f0f3f7;

				  span {
					  margin-right: 1rem;
					  padding: 0.5rem;
					  background: #fff;
					  border-radius: 0.75rem;
					}
				}

				.affiliate-link {
				  padding: 0.75rem 1.5rem;
				  background: linear-gradient(325deg, #ff5f5f, #ffcd5d);
				  border-radius: 0.75rem;
				  font-weight: 600;
				  color: #fff;
				  text-transform: uppercase;
				  font-size: 1rem;
				  height: 44px;
				  line-height: 1;
				  display: flex;
				  align-items: center;
				  justify-content: flex-start;
				  width: fit-content;

				  &:hover {
					  background: #ff5f5f;
					}
				}

				.message {
					&.out-of-stock {
					  font-weight: 600;
					  border-radius: 0.75rem;
					  color: #424242;
					  background: #fff;
					}
				}

	    	> div {
	    		flex: 1;

	    		&.content {
						padding: 2rem 0 2rem 2rem;

						.title {
							font-size: 1.8rem;
							margin-bottom: 1.5rem;
							font-weight: 600;
							line-height: 1.5;
					  }

					  .summary {
						  line-height: 1.5;
						  font-size: 1.1rem;
						  margin-bottom: 1.5rem;
						}

						.licenses, .action {
							margin-right: 1rem;
							border-radius: 0.75rem !important;

							.menu {
							  border-radius: 0.75rem !important;
							  margin-top: 1rem !important;
							  box-shadow: none !important;
							}

							&.visible, &:hover, &.active {
							  background: #fff;
							}

							> .text {
							  font-weight: 600;
							  color: #000;
							  line-height: 1.5;
							  font-size: 1.2rem;
							}
						}

						.action {
							margin-right: 0;
							background: #ffe717;
    					border: none;
					    display: flex;
					    align-items: center;
					    justify-content: center;

    					&:hover {
    						background: #ffe717;
    					}

    					&.single {
						    background: #fff;
						    border: 2px solid #bfbfbf;
						    border-left: 0;
						    border-radius: 0 0.75rem 0.75rem 0 !important;
						    width: fit-content;
						    max-width: fit-content;
						    padding: .75rem 1rem;

								&:hover {
									background: #fff;
								}
    					}
						}

						.price-stock {
							display: flex;
							align-items: center;
							margin-bottom: 1.5rem;
							flex-wrap: wrap;
						}

						.stock {
						  margin-left: auto;
						  font-size: 1.5rem;
						  font-weight: 600;
						  line-height: normal;
						  color: #2e2e2e;
						}

					  .price {
					    font-size: 1.5rem;
					    font-weight: 600;
					    line-height: normal;
					    padding: 0.75rem 1.5rem 0.5rem;
					    display: table;
					    border-radius: 0 .75rem .75rem 0;
					    background: #7238ac;
					    color: #fff;

						  &.free {
							  background: #00bf86;
							}
						}

						.price-wrapper {
							&.has-promo {
								.price {
									&:not(.promo):not(.free) {
								    background: linear-gradient(45deg, aliceblue, #dfdfdf);
								    margin-top: 1rem;
								    color: #545454;
								    text-decoration: line-through;
									}
								}
							}
						}

						.promo-count, .free-count {
						  font-weight: 600;
						  margin-bottom: 1rem;

						  span {
							  color: salmon;
							  font-weight: 700;
							}
						}

						.product-subscriptions {
							padding: 1.5rem;
							background: linear-gradient(45deg, rgb(246 242 250 / 71%), transparent);
							border-radius: 0.75rem;
							border-left: 0.25rem solid #7238ac;

							p {
							  margin-bottom: 1.5rem;
							  font-weight: 500;
							}

							.items {
							  display: flex;
							  flex-wrap: wrap;
							  margin: -0.5rem;

							  a {
							    font-weight: 500;
							    padding: 1rem;
							    margin: 0.5rem;
							    line-height: 1;
							    text-align: center;
							    color: #000000;
							    border-radius: 0.75rem;
							    background: #ffffff;
							    max-width: 50%;
							    text-transform: uppercase;
							    font-size: 1rem;

							    &:first-letter {
								    color: #ff4a4a;
								    font-weight: 700;
								  }

									&:hover {
										font-weight: 600;
									}
								}

								span {
								  display: block;
								  padding: 0.5rem;
								  background: slategray;
								  border-radius: 0.5rem;
								  color: #fff;
								  font-weight: 600;
								  position: relative;
								  z-index: 0;
								  overflow: hidden;
								  line-height: 1.5;

								  &:before {
									  position: absolute;
									  content: '';
									  width: 0;
									  height: 100%;
									  left: 0;
									  top: 0;
									  background: #424e5b;
									  z-index: -1;
									  transition: width .5s;
									}
								}
							}
						}
	    		}

	    		.price-container {
						display: flex;
						margin-bottom: 1.5rem;

						> div {
							flex: 1;
							max-width: 50%;

							&.price {
							  margin-right: 1rem;
							  max-width: fit-content;
							}
						}
					}
	    	
	    		&.image {
				    max-width: 420px;
				    width: 100%;
				    display: flex;
				    align-items: center;
				    justify-content: center;
				    padding: 2rem;
				    position: relative;
				    flex-direction: column;

				    .preview-url {
						  position: absolute;
						  right: 2.75rem;
						  top: 2.75rem;
						  padding: 0.8rem 1rem .45rem;
						  font-size: .9rem;
						  font-weight: 800;
						  background: linear-gradient(45deg, #34235040, #878787a3);
						  border-radius: 500px;
						  color: #fff;
						  text-transform: uppercase;
						  line-height: 1;
						  backdrop-filter: blur(4px);
						  z-index: 1;
						}

		        .cover {
		        	position: relative;

		        	img {
			          width: auto;
			          max-width: 100%;
			          border-radius: .75rem;
			        }
		        }

		        .screenshots {
						  display: flex;
						  width: 100%;
						  margin: 1rem -0.25rem -0.25rem -0.25rem;
						  flex-wrap: nowrap;
						  overflow: hidden;

						  a {
							  min-width: 70px;
							  height: 70px;
							  background-position: center;
							  background-size: cover;
							  margin: 0.25rem;
							  border-radius: 0.5rem;
							}
						}

		        &.video:hover {
		        	.video {
		        		display: flex;
		        	}
		        }

		        .video {
					    background: rgba(0, 0, 0, 0.06);
					    position: absolute;
					    top: 2rem;
					    left: 2rem;
					    height: calc(100% - 4rem);
					    width: calc(100% - 4rem);
					    align-items: center;
					    justify-content: center;
					    z-index: 1;
					    -webkit-backdrop-filter: blur(1px);
					    backdrop-filter: blur(1px);
					    display: none;
					    cursor: pointer;
					    border-radius: 0.75rem;
					
					    img {
						    width: 80px;
						    filter: invert(1);
							}
						}

						.player {
					    width: 100%;
					    bottom: 0;
					    left: 0;
					    border-radius: 0 0 0.75rem 0.75rem;
					    overflow: hidden;
						}
		      }
	    	}
    	}

    	> .body {
			  padding: 0;

			  > .ui.menu {
				  margin: 0;
				 	background: linear-gradient(45deg, #7238ac, #8a72e4, #7238ac);
    			overflow: hidden;

				  &::-webkit-scrollbar {
					  width: 5px;
					  height: 5px;
					}

					&:hover {
						overflow: auto;
					}

					&::-webkit-scrollbar-track {
					  background: #EAEAEA;
					}

					&::-webkit-scrollbar-thumb {
					  background: #b061ff;

					  &:hover {
						  background: #582a86;
						}
					}

				  > .item {
				    padding: 1rem 1.5rem;
				    flex: none;
				    font-size: 1rem;
				    font-weight: 600;
				    text-align: center;
				    align-items: center;
				    justify-content: center;
				    height: 55px;
				    border-radius: 0;
				    text-transform: uppercase;
				    margin: 0;
				    color: #fff;
				    min-width: fit-content;
				    width: 120px;

				    &:first-child {
				      margin-left: 0;
				    }

				    &:last-child {
				      margin-right: 0;
				    }

				    &.active, &:hover {
				      background: #582a86;
				      color: #fff;
				    }

				    &:not(:last-child) {
				      border-right: 2px solid #fff;
				    }
				  }
				}

				.tabs {
					.content.item {
						display: none;
						padding: 2rem;

						&.active {
							display: block;
							min-height: 400px;
						}

						&[data-tab="table_of_contents"] {
							.segments {
							  border: none;
								box-shadow: 0 6px 10px 0px rgb(185 189 202 / 12%) !important;
								border-radius: 0.75rem !important;
							  overflow: hidden;
							}

							.segment.secondary {
							  background: #faf8fd;
							  font-weight: 600;
							}
						}

						&[data-tab="comments"], &[data-tab="reviews"] {
							.ui.form.comment-form, .ui.form.review-form {
							  margin-bottom: 3rem;
							  max-width: 600px !important;
							  width: 100%;

							  .ui.rating {
							  	margin-left: 1rem;
							  }

							  .actions {
								  height: auto;
								  display: flex;
								  align-items: center;
								  margin-top: 1rem;

								  .button {
								  	margin-top: 0;
								  }

								  .ui.rating {
								  	font-size: 1.5rem;
								  }
								}

							  textarea {
									border-radius: 0.75rem !important;
									font-size: 1.2rem;
							  }

							  button {
								  margin: 1rem 0 0 0;
								  font-size: 1.1rem;
								  border-radius: 0.75rem;
								  font-weight: 600;
								  background: #fff;
								  color: #007cb2;
								  border: 1px solid #007cb2;
								  padding: 0.9rem 1.5rem 0.75rem;

								  &:hover {
									  background: #007cb2;
									  color: #fff;
									}
								}

								.reply-to, .edit-comment, .edit-review {
								  font-weight: 600;
								  padding: 0.5rem 1rem;
								  background: #f9f9f9;
								  display: table;
								  font-size: 1rem;
								  line-height: 1;
								  border-radius: 0.5rem;
								  margin: 1rem 0;
								  color: #555;
								  text-transform: capitalize;
								}
							}

							.comments, .reviews {
							  max-width: 600px;
							}

							.comment, .review {
								&.main:not(:last-child), &.sub:not(:last-child) {
								  margin-bottom: 2rem;
								}

							  .header {
							    display: flex;
							    align-items: center;
							    font-weight: 600;

							    > img {
								    width: 45px;
								    height: 45px;
								    min-width: 45px;
								    min-height: 45px;
								    margin-right: 1rem;
								    border-radius: 500px;
							    }

							    .content {
									  display: flex;
									  flex-direction: column;
									  justify-content: space-between;
									  height: 40px;
									  line-height: 1;
									  color: #2a2a2a;
									  font-weight: 700;
									  text-transform: capitalize;
									  position: relative;

									  .date {
										  color: #797979;
										  font-weight: 500;
										  font-size: 1rem;
										}
									}

									.actions {
										margin-left: auto;

										img {
										  margin-right: 0;
										  width: 20px;
										  height: auto;
										  transform: rotate(90deg);
										}

										.menu {
										  border-radius: 0.75rem;
										  box-shadow: none;

										  .item {
											  color: #000;
											  font-weight: 500;

											  &:hover {
												  font-weight: 700;
												}
											}
										}
									}
							  }

							  .body {
							    margin-top: 1rem;
							    color: #3b3b3b;
    							font-weight: 500;
							  }

								.footer {
								  display: flex;
								  margin-top: 1rem;
								  align-items: center;

								  .reactions {
								    align-items: center;
								    justify-content: center;
								    display: flex;
								    position: relative;

								    .popup {
											position: absolute;
											left: -1rem;
											bottom: 100%;
											width: 0;
											height: auto;
											padding-bottom: 1rem;
											transition: width .25s;
											visibility: hidden;
								    }

								    .items {
											align-items: center;
											justify-content: center;
											background: #ffffff;
											border-radius: 0.75rem;
											box-shadow: 0 0 20px 0px rgb(128 128 128 / 17%);
											display: flex;
											height: 40px;
											padding: 0 0.5rem;
											overflow: hidden;

											a {
											  width: 25px;
											  height: 25px;
											  background-position: center;
											  background-size: cover;

											  &:not(:last-child) {
											  	margin-right: 0.5rem;
											  }
											}
								    }

								    &:hover .popup {
								    	visibility: visible;
								    	width: 200px;
								    }
								  }

								  .saved-reactions {
								    display: flex;

								    > div {
								      display: flex;
								      align-items: center;
								      padding: .35rem .5rem;
								      background: ghostwhite;
								      border-radius: .5rem;
								      cursor: pointer;

								      img {
								        width: 17px;
								        margin-right: .5rem;
								        transition: transform .25s;
								      }

								      &:hover {
								      	img {
								      		transform: scale(1.25);
								      	}
								      }
								    }

								    span {
								      position: relative;
								      bottom: -3px;
								      font-weight: 600;
								      font-size: .9rem;
								    }
								  }

								  > div:not(:last-child) {
								    margin-right: 1rem;
								    padding-right: 1rem;
								    border-right: 2px solid #dfdfdf;
								    min-height: 25px;
								  }

								  .reply {
								    font-weight: 600;
								    cursor: pointer;

								    &:hover {
										  color: #ff6021;
										}
								  }

								  .saved-reactions > div:not(:last-child) {
								    margin-right: .5rem;
								  }

								  .reactions {
								  	> img {
									    width: 20px;
									    cursor: pointer;
									    transition: transform .25s;
									  	
									  	&:hover {
									  		transform: scale(1.25);
									  	}
									  }
								  }
								}
							}

							.subcomments {
							  padding-left: 3rem;
							  position: relative;
								margin-bottom: 2rem;

								&:before {
								  content: '';
								  position: absolute;
								  left: 0;
								  height: 100%;
								  width: .25rem;
								  top: 0;
								  background: linear-gradient(45deg, #f7f7f7, #cbcbcb);
								  border-radius: 100%;
								}
							}

							.review {
								.rating {
								  display: flex;
								  height: 17px;
								  margin-bottom: 1rem;

								  svg:not(:last-child) {
									  margin-right: 0.25rem;
									}
								}
							}
						}

						&[data-tab="faq"] {
							.item {
								&:not(:last-child) {
								  margin-bottom: 1.5rem;
								}

								.question {
								  font-weight: 600;
								  margin-bottom: .5rem;
								}

								.answer span {
								  font-weight: 700;
								  color: #ff8800;
								}

								.question span {
									font-weight: 700;
								  color: #31c0ff;
								}
							}
						}

						&[data-tab="files"] {
							.item {
    						padding: .75rem 1rem;
							}
						}
					}
				}

				.stream-player {
				  position: relative;
				  margin: -2rem;
    			width: auto;

				  .controls {
				    position: absolute;
				    width: 100%;
				    height: 50px;
				    left: 0;
				    top: calc(100% - 50px);
				    display: flex;
				    padding: .75rem;
				    z-index: 1;
				    background: rgb(0 0 0 / 42%);

				    .play, .stop, .volume, .maximize {
				      max-width: 30px;
				      display: flex;
				      align-items: center;
				      justify-content: center;
				      height: 30px;
				      cursor: pointer;
				    }

				    .current-time {
				    	flex: none;
				    	display: flex;
						  color: #fff;
						  align-items: center;
						  margin-right: 1rem;
						  height: 30px;
						  position: relative;
						  bottom: -2px;
						  font-size: 1rem;
				    }

				    .volume, .stop {
				    	margin-right: 1rem;
				    }

				    .volume {
				    	position: relative;

				    	div {
						    display: none;
						    position: absolute;
						    bottom: 100%;
						    left: 0;
						    height: 125px;
						    width: 30px;
						    cursor: default;

							  > span {
									position: absolute;
									bottom: 0;
									left: 10px;
									width: 10px;
									height: 120px;
									margin-bottom: 5px;
									background: rgb(255 255 255 / 50%);
									border-radius: 100px;
									overflow: hidden;

									span {
									  position: absolute;
									  bottom: 0;
									  left: 0;
									  height: 100%;
									  width: 100%;
									  background: white;
									}
								}
							}

							&:hover {
								div {
									display: block;
								}
							}
				    }

				    img {
				      width: 30px;
				      filter: invert(1);
				    }

				    > div {
				      flex: 1;
				    }

				    > .wave {
					    margin: auto 1.5rem;
					    border-radius: 100px;
					    background: rgb(255 255 255 / 12%);
					    height: 30px;
					    position: relative;
					    overflow: hidden;
					    -webkit-mask-image: url(/assets/images/wave-3.png);
					    -webkit-mask-position: center;
					    -webkit-mask-size: contain;
					    -webkit-mask-repeat: repeat;

						  .time {
							  position: absolute;
							  left: 0;
							  top: 0;
							  width: 0%;
							  height: 100%;
							  z-index: 9999;
							  border-radius: 100px 0 0 100px;
							  background: linear-gradient(45deg, #002941, rgb(24 255 238 / 84%));
							  opacity: .5;
							}
						}
				  }

				  .video {
				    position: absolute;
				    top: 0;
				    left: 0;
				    width: 100%;
				    height: calc(100% - 50px);
				    background: transparent;
				    z-index: 1;
				  }

				  video {
				    position: relative;
				    z-index: 0;
				  }

				  .download {
				    position: absolute;
				    z-index: 9;
				    top: 0.5rem;
				    right: 0.5rem;
				    color: #fff;
				    border-radius: 100px;
				    display: flex;
				    padding: 0.5rem;

				    img {
						  width: 25px;
						  height: 25px;
						  opacity: .3;
						}

						&:hover img {
							opacity: 1;
						}
					}
				}
			}
    }

    .right-side {
			max-width: 350px;
			width: 100%;
			padding: 1.5rem;
			position: relative;
			overflow-y: clip;

			* {
				position: relative;
			}

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				box-shadow: -5px 0px 14px 7px rgb(128 128 128 / 5%);
			}

			.extra {
				display: flex;
				align-items: center;
				margin-bottom: 1.5rem;

				.share {
			    margin-right: 1rem;
			    padding-left: 0;
			    padding-right: 1rem;

					.menu {
						background: #fff;
						min-width: 150px;
						margin-top: 1rem;
						border-radius: 0.75rem;
						box-shadow: none;
						overflow: auto;
						position: absolute;
						max-height: 250px;

					  .item {
						  display: flex;
						  align-items: center;
						  min-width: 150px;
						  width: 100%;
						  padding: 1rem !important;

						  img {
							  width: 25px;
							  height: 25px;
							  border-radius: 100px;
							  overflow: hidden;
							}

						  &:not(:last-child):after {
							  content: '';
							  position: absolute;
							  left: 0;
							  bottom: 0;
							  width: 100%;
							  height: 1px;
							  background: #e4e4e4;
							}
						}
					}
				}

				.button {
			    font-size: 1.1rem;
			    font-weight: 600;
			    color: #000;
			    background: #f8fafc;
			    border-radius: 0.75rem;
			    line-height: 1.25;
			    text-align: left;
			    white-space: nowrap;
			    display: flex;
			    align-items: center;
			    min-height: 45px;
			    max-height: 45px;
			    flex: 1;

					>.text {
				    display: flex;
				    align-items: center;

				    span {
						  pointer-events: none;
						  height: 45px;
						  display: flex;
						  align-items: center;
						  justify-content: center;
						  padding-left: 1rem;
						  padding-right: 1rem;
						  margin-right: 1rem;
						  border-radius: 0.75rem 0 0 0.75rem;
						  background: #616161 !important;
						}

						img {
							margin: 0;
							filter: invert(1);
							width: 20px;
							height: 20px;
						}
					}
				}

				.button.like {
					margin-right: 0;
					padding-left: 0;
					padding-right: 1rem;
					background: #f8fafc;

					span {
						pointer-events: none;
				    height: 45px;
				    display: flex;
				    align-items: center;
				    justify-content: center;
				    padding-left: 1rem;
				    padding-right: 1rem;
				    margin-right: 1rem;
				    border-radius: 0.75rem 0 0 0.75rem;
				    background: #ffe855;
					}

					img {
					  width: 20px;
					  filter: invert(0);
					  min-width: 20px;
					}

					div {
					  display: none;
					  pointer-events: none;
					}

					&.active {
				    background: #fff;
				    box-shadow: 0 0 25px 0px rgb(151 151 151 / 26%);

				  	span {
				  		background: linear-gradient(45deg, #ff5757, #ff7447);

				  		img {
							  filter: invert(1);
							}
				  	}

				  	div.added {
						  display: block;
						}
				  }

				  &:not(.active) div.add {
					  display: block;
					}
				}
			}


			.specs {
				.item {
				  display: flex;
					align-items: center;
				  
				  &:not(:last-child) {
					  margin-bottom: 0.75rem;
					}
				
				  span {
					  flex: 1;
					  min-height: 50px;
				    display: flex;
				    align-items: center;

					  &:nth-child(odd) {
							font-weight: 500;
							margin-right: 1rem;
							border-radius: 0.75rem;
							color: #000000;
							max-width: 120px;
							background: #fff;
							font-size: 1.2rem;
						}

						&:nth-child(even) {
						  flex: 1;
						}
					}
				}
			}
    }
  }
}


#reactions {
  background: transparent;
  box-shadow: none;
  max-width: 400px;
  width: 100%;

	.header {
		border-radius: 1rem 1rem 0 0 !important;
		background: #ffd439;

		.wrapper {
			display: flex;
		}

		.name {
	    width: 45px;
	    height: 45px !important;
	    display: block;
	    text-align: center;
	    margin: .5rem 0.5rem 1rem;
	    background-position: center;
	    background-size: cover;
	    border: 3px solid #fff;
	    border-radius: 100px;
	    filter: contrast(1.1);
	    position: relative;

	    &.active * {
			  color: #266e6c !important;
			  font-weight: 700 !important;
			}

			&.like {
				background-image: url('/assets/images/reactions/like.png');
			}

			&.love {
				background-image: url('/assets/images/reactions/love.png');
			}

			&.angry {
				background-image: url('/assets/images/reactions/angry.png');
			}

			&.funny {
				background-image: url('/assets/images/reactions/funny.png');
			}

			&.sad {
				background-image: url('/assets/images/reactions/sad.png');
			}

			&.wow {
				background-image: url('/assets/images/reactions/wow.png');
			}

			.label {
			  position: absolute;
			  bottom: -2.5rem;
			  color: #000;
			  left: 0;
			  font-size: .9rem;
			  width: 100%;
			  text-align: center;
			  text-transform: capitalize;
			  font-weight: 600;
			}

			.count {
			  position: absolute;
			  top: -1.5rem;
			  right: 0;
			  font-size: .8rem;
			  text-align: center;
			  text-transform: capitalize;
			  font-weight: 600;
			  color: #000;
			}
		}
	}

	.content {
		border-radius: 0 0 1rem 1rem !important;

		.wrapper {
			height: 350px;
			overflow-y: auto;
		}

		.users {
			flex-wrap: wrap;
			display: none;

			&.active {
				display: flex;
			}

			.user {
				width: 50%;
		    padding: .5rem;
		    display: flex;
		    align-items: center;
		    white-space: nowrap;
		    text-overflow: ellipsis;
		    overflow: hidden;

		    > .avatar {
				  min-width: 30px;
				  height: 30px;
				  width: 30px;
				  margin-right: .5rem;
				}

				img.avatar {
				  width: 30px !important;
				  height: 30px !important;
				}

				.text {
				  text-overflow: ellipsis;
				  overflow: hidden;
					text-transform: capitalize;
				}
			}
		}
	}
}


#user {
	min-height: 500px;

	.table-wrapper {
		padding: 2rem;
		border-radius: 1rem;
		width: 100%;

		table {
			border: none;
			font-size: 1.1rem;
		}
	}

	> .items {
		>.header {
			&:not(.extra) {
				padding: 2rem;
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;
				background: aliceblue;
			}

			&.extra {
				padding: 1rem 2rem;
		    background: #fef8cd;
		    position: relative;
		    overflow: hidden;

		    * {
				  position: relative;
				  color: #000;
				}

				&:before {
				  content: "";
				  position: absolute;
				  left: -5%;
				  top: 0;
				  width: 33%;
				  height: 100%;
				  background: #ffe57b;
				  transform: skewX(20deg);
				  border-right: 0.75rem solid #fff;
				}

		    .title {
		    	font-size: 1.8rem;
			    line-height: 1.5;
			    font-weight: 700;
		    }
			}
		}
	}

	.profile {
		.button {
			border-radius: 0.75rem;
		}

		>.header {
			.image {
				width: 100px;
				height: 100px;
				background-position: center;
				background-size: cover;
				margin-bottom: 1rem;
				border-radius: 0.75rem;
			}

			>.content {
				&:first-child {
					margin-right: 1.5rem;

					.button {
						border-radius: .75rem;
					}
				}

				&:last-child {
					font-size: 1.2rem;
					line-height: 1.5;
					color: #000;

					.name {
						font-size: 1.3rem;
						font-weight: 600;
						margin-bottom: 1rem;
					}

					>* {
						&:not(:last-child) {
							margin-bottom: .5rem;
						}
					}

					.email {
						.verified {
							font-weight: 600;
							color: #008997;
							font-size: 1.1rem;
						}
					}
				}
			}
		}

		>form {
			padding: 2rem;

			input {
				border-radius: .75rem;
			}

			.dropdown {
				box-shadow: none !important;

				&, .menu {
					border-radius: .75rem !important;
				}

				.menu {
					margin: 1rem 0 !important;
					box-shadow: none !important;
				}
			}
		}
	}

	.notifications {
		> .items {
	    padding: 2rem;

			.item {
		    display: flex;
		    align-items: flex-start;
		    font-size: 1.1rem;
		    line-height: 1.5;
		    background: rgb(250 250 255 / 61%);
		    padding: 0.75rem;
		    border-radius: 0.75rem;

		    &:not(:last-child) {
				  margin-bottom: 0.75rem;
				}

				.image {
					width: 60px;
					height: 60px;
					max-width: 60px;
					max-height: 60px;
					min-width: 60px;
					background-position: center;
					background-size: cover;
					margin-right: 1.5rem;
					border-radius: 0.75rem;
				}

				.text {
				  font-weight: 500;
				}

				.date {
				  color: grey;
				  font-size: 1rem;
				  margin-top: 0.5rem;
				}
			}
		}

		.ui.pagination.menu {
		  margin: 0 2rem;
		}
	}

	.invoices {
		.table.wrapper {
			padding: 2rem;
		}

    table {
		  border-radius: .75rem !important;
		  border: none !important;
		}

		thead {
		  background: #ff6f55;
		  border-radius: 0.75rem 0.75rem 0 0 !important;

		  th {
			  color: #fff !important;
			  border: none;
			}

			tr:first-child> {
				th:first-child {
				  border-radius: 0.75rem 0 0 0;
				}

				th:last-child {
				  border-radius: 0 0.75rem 0 0;
				}
			}
		}

		.button {
		  border-radius: .75rem !important;
		  background: #fff !important;
		  border: 1px solid #c8c8c8;
		  margin: 0;
		}

		.ui.pagination.menu {
		  margin: 0 2rem;
		}
	}

	.coupons {
		.items-list {
			font-size: 1.1rem;
      padding: 2rem;
    }

    .titles {
    	display: flex;
      justify-content: space-between;
      background: #ff4b4b;
      border-radius: .75rem .75rem 0 0;
      color: #fff;

      > div {
        flex: 1;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 600;
        font-size: 1.1rem;
        padding: 1rem;

        &.code {
          text-align: left;
        }
      }
    }

    .content {
      display: flex;
      align-items: center;
      text-align: left;

      > div {
          text-align: center;
          flex: 1;
          padding: 1.25rem;

          &.code {
          	text-align: left;
          }
      }

      &.expired {
			  background: ghostwhite;
			  opacity: .5;
			}
  	}
  }

	.purchases {
		.titles {
		  display: flex;
		  align-items: center;
			background: slateblue;
			border-radius: 1rem 1rem 0 0;
			
			* {
			  color: #fff;
			}

		  > div {
		  	padding: 1rem;
			  flex: 1;
			  font-size: 1.3rem;
			  text-align: center;

			  &.name {
			  	flex: 2;
			  }
			}
		}

		thead {
			th:not(.link) {
				text-align: center;
			}
		}

		tbody {
			.parent td:not(.link) {
				text-align: center;
			}
		}

		tr.parent {
			font-weight: 500;

			.status {
				div {
			    padding: 0.25rem 0.75rem;
			    text-align: center;
			    font-weight: 500;
			    font-size: 1rem;
			    border-radius: 0.5rem;
			    border: 1px solid #505050;
			    font-family: system-ui;
			    color: #000000;
			    display: table;
			    margin: auto;
			    min-width: 80px;
				}

				&.paid div {
				  background: #d5d5d5;
				  color: #000;
				  border-color: #d5d5d5;
				}
			}

		  .links {
			  text-align: center;

			  a {
				  display: flex;
				  justify-content: center;
				}

				img {
				  width: 30px;
				  height: 30px;
				}
			}
		}

		.products {
			display: none;

			&.active {
				display: table-row;
			}

			tr td {
				background: #fff;

				&:last-child {
				  border-right: 1px solid rgba(34,36,38,.1);
				}
			}

			.button {
				color: #645aff;
				font-weight: 500;
				border-radius: 0.5rem;
				font-family: system-ui;
				font-size: 1.1rem;
				background: #fff;
				box-shadow: 0 0 0 2px #645aff;
				padding: 0.75rem 1rem;

			  &:hover {
			    background: #645aff;
			    color: #fff;
			  }

			  .menu {
			    margin-top: 0.25rem;
			    border-radius: 0.75rem;
			    box-shadow: none;
			  }

			  .item {
				  font-size: 1.1rem;
				  font-weight: 600;
				}
			}
		}
	}

	.subscriptions {
		.wrapper {
		  width: 100%;
		  display: table;
		}

		.item {
			display: flex;
			font-size: 1.1rem;
			text-align: center;

			> div {
			  flex: 1;
			  padding: 1rem;
			  display: flex;
			  justify-content: center;
			  align-items: center;
			}

			&.titles {
			  background: #efefef;
			  border-radius: 1rem 1rem 0 0;
			  
			  div {
				  font-size: 1.2rem;
				  display: flex;
				  justify-content: center;
				  align-items: center;
				  color: #000;
				}
			}

			&:not(.titles):not(:last-child) {
			  border-bottom: 1px solid #f6f6f6;
			}
		}
	}

	.subscriptions {
		.items-list {
			font-size: 1.1rem;
      padding: 2rem;
	    max-width: 1100px;
	    margin: auto;
	    width: 100%;

      .titles {
			    display: flex;
			    justify-content: space-between;
			    background: #ffffff !important;
			    border-radius: 0.75rem 0.75rem 0 0;
			    color: #000;
			    border-bottom: 2px solid #ebebeb;

          > div {
              flex: 1;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-weight: 600;
              font-size: 1.1rem;
              padding: 1rem;

              &.name {
                  text-align: left;
              }

              &.category {
                  text-align: left;
              }

              &.cover {
                  max-width: 80px;
              }
          }
      }

      .content {
          display: flex;
          align-items: center;
          text-align: left;
    			background: #fff;

          > div {
              text-align: center;
              flex: 1;
              padding: 1.25rem;

              &.status {
              	.label {
								  min-width: 100px;
								  font-size: 1.1rem;
								  font-weight: 600;
								  border-radius: 0.75rem;
								}
              }

              &.download {
                  .button {
                      border-radius: .75rem;
                      font-weight: 600;
                  }

                  .dropdown .menu {
									  border-radius: 0.75rem !important;
									  box-shadow: none !important;
									}
              }

              &.name {
                  text-align: left;

                  a {
                      display: block;

                      &:hover {
                          color: #000;
                          font-weight: 600;
                      }
                  }
              }

              &.category {
                  text-align: left;

                  a {
                      display: block;

                      &:hover {
                          color: #000;
                          font-weight: 600;
                      }
                  }
              }

              &.cover {
                  max-width: 80px;

                  a {
                      width: 50px;
                      height: 50px;
                      display: block;
                      background-position: center;
                      background-size: cover;
                      border-radius: .75rem;
                  }
              }
          }
      }
    }
	}

	.collection {
		>.cards {
			margin: 0;
			padding: 1rem;

			.content {
				display: flex;
				padding: 0;

				.cover {
					height: 100px;
					width: 100px;
					min-width: 100px;
					border-radius: .75rem;
					margin-right: 1rem;
					background-position: center;
					background-size: cover;
				}

				.details {
					position: relative;

					a {
						display: block;
						font-size: 1.1rem;
						line-height: 1.5;

						&:hover {
							color: #000;
						}
					}

					a.name {
						font-weight: 600;
						margin-bottom: .5rem;
					}

					a.category {
				    font-weight: 500;
				    color: grey;
				    font-size: 1rem;
				    max-width: 120px;
				    white-space: normal;
				    text-overflow: ellipsis;
				    overflow: hidden;
					}

					.trash {
				    position: absolute;
				    right: 0;
				    bottom: 6px;
				    font-size: 1.2rem;
				    color: #ff9e00;
				    cursor: pointer;

						&:hover {
							font-weight: 600;
						}
					}
				}
			}

			.card {
				height: 100px;
				max-height: 100px;
				overflow: hidden;
				border-radius: .75rem;
			}
		}
	}

	.credits {
		>.body {
			padding: 1rem 2rem 3rem;

			.card {
				box-shadow: 0 0 20px 0px rgb(240 240 240 / 34%) !important;
				border-radius: .75rem;
				overflow: hidden;

				>.content {
					display: flex;
					align-items: center;

					img {
						width: 50px;
						height: 50px;
						min-width: 50px;
						min-height: 50px;
					}

					.content {
						padding-left: 1.5rem;
						font-size: 1.1rem;
						font-weight: 600;
						text-align: center;
						text-transform: capitalize;

						.name {
							font-size: 1.2rem;
							font-weight: 600;
							margin-bottom: 1rem;
							line-height: 1.5;
						}

						.count {
							font-weight: 600;
							font-size: 1.3rem;
							padding: .75rem 1rem .5rem;
							border-radius: .75rem;
							color: #385e83;
							background: rgb(240 248 255 / 28%);
						}
					}
				}
			}
		}

		.affiliate-earnings {
			margin-top: 2.5rem !important;

			> .header {
				background: #fbfdff;
				padding: 2rem;

				img {
					margin-right: 1.5rem;
				}
		
				.title {
					div {
						font-size: 1.3rem;

						&:first-child {
							font-weight: 600;
							margin-bottom: 1rem;
						}
		
						&:last-child {
							font-weight: 500;
							color: #8c8c8c;
						}
					}
				}

				.date {
				  margin-left: auto;

				  input {
					  height: 45px;
					  border-radius: .75rem;
					  font-size: 1.1rem;
					}
				}
			}

			> .grid {
        min-height: 404px;
        height: 404px;
        overflow-y: visible;

        .row {
          display: flex;

          span {
            content: "";
            position: absolute;
            bottom: 0;
            right: 17%;
            width: 70%;
            height: 1px;
            background: linear-gradient(-180deg, #ff8d5c, #ff6b6b);
            max-height: 305px;
            border-radius: 1rem 1rem 0 0;

            i {
              font-size: 1rem;
              position: absolute;
              left: -3px;
              line-height: 1;
              display: none;
            }
          }

          &:last-child div {
            height: 40px;
            align-items: flex-end;
            justify-content: center;
          }

          div {
            flex: 1;
            height: 30px;
            text-align: right;
            position: relative;
            display: flex;
            align-items: flex-end;
            line-height: 1 !important;
            justify-content: flex-end;

            &:after {
              content: '';
              position: absolute;
              right: 0;
              bottom: 0;
              width: 1px;
              height: 100%;
              border-right: 2px dashed #e8e8e8;
            }

            &:first-child {
              padding-right: 1rem;
              min-width: 45px;
              justify-content: flex-end;
            }
          }

          &:not(:last-child) div:not(:first-child):before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            border-bottom: 2px dashed #e8e8e8;
          } 
        }

        .wrapper {
          min-width: 100%;
          padding-right: 1rem;
        }
      }
		}

		.orders {
			margin-top: 2.5rem !important;

			> .header {
				background: #fbfdff;
				padding: 2rem;

				img {
					margin-right: 1.5rem;
				}
		
				.title {
					div {
						font-size: 1.3rem;

						&:first-child {
							font-weight: 600;
							margin-bottom: 1rem;
						}
		
						&:last-child {
							font-weight: 500;
							color: #8c8c8c;
						}
					}
				}

				.date {
				  margin-left: auto;

				  input {
					  height: 45px;
					  border-radius: .75rem;
					  font-size: 1.1rem;
					}
				}
			}

			.body {
				display: block;

				thead th {
				  background: white;
				  color: #293f55;
				  font-weight: 600;
				  text-align: center;
				}

				tfoot th { 
					background: white;
				}

				td.items {
					.item:not(:last-child) {
					  border-bottom: 2px dashed #ebebeb;
					  padding-bottom: 0.75rem;
					  margin-bottom: 0.75rem;
					}
				}

				tbody {
					td {
					  background: rgb(225 190 255 / 3%);
					}
				}

				.table {
				  border: none;
				}

				.label {
				  font-weight: 500;
				  font-size: 1rem;
				  border-radius: 0.75rem !important;
				  width: 100%;
				}
			}
		}

		.ui.pagination.menu {
			margin: 0 !important;
		}
	}
}


#auth {
	.ui.message {
    border-radius: 1rem;
    line-height: 1.5;
    font-weight: bold;
	}
	
	.card {
		box-shadow: 0px 6px 20.1px 4.9px rgba(176, 191, 238, 0.12) !important;
		border-radius: 1rem !important;
		overflow: hidden;
		border-bottom: .5rem solid #483d8b;
		
		input {
			border-radius: 100px;
		}

		label:not(.checkbox) {
			padding-left: 1rem;
		}

		.ui.dropdown {
			border-radius: 100px;

			.menu {
		    border-radius: 1rem !important;
		    box-shadow: none !important;

		    .item {
		    	font-size: 1.1rem;
					line-height: 1.3;

					&:not(:last-child) {
					  border-bottom: 1px solid #f3f3f3;
					}
		    }
			}
		}

		.content {
			padding: 1rem 1.5rem !important;

			&.logo {
		    background: white;
		    border-bottom: 0.5rem solid #7463dd;
		    min-height: 100px;
		    display: flex;
		    align-items: center;
		    justify-content: center;

    		a {
					display: block;
    		}

    		img {
    			max-width: 210px;
			    height: auto;
			    width: 100%;
    		}
			}

			.title {
				color: #2d73ad;
			}
		}
	}

	&.newsletter-unsubscribe {
		.logo {
			background: indianred !important;

			a {
			  font-size: 2rem;
			  padding: .5rem !important;
			  margin: 0 !important;
			  font-weight: 600;
			  color: #fff;
			}
		}

		h2 {
		  color: #464646;
		  font-weight: 600;
		  font-size: 1.3rem !important;
		}
	}
}


#blog {
	padding: 2rem;

	> .ui.secondary.menu {
		padding: 1.5rem;
		margin: -2rem -2rem 2rem;
		box-shadow: 0 0 20px 10px #ededed;

		> .header {
	    background: -webkit-linear-gradient(56deg, #00fdff, #ff6c00 90%);
	    -webkit-background-clip: text;
	    -webkit-text-fill-color: transparent;
	    font-size: 1.8rem;
	    font-weight: 600;
	    line-height: 1.3;
	    padding: 0;
		}

		.search .input {
		  font-size: 1.2rem;
		  height: 50px;

		  input {
			  border-color: #f4f4f4;
			  border-radius: 1rem;
			  color: #000;
			  font-weight: 500;
			}

			i {
			  margin-right: .5rem;
			}
		}

		.ui.dropdown {
	    height: 45px;
	    width: 45px;
	    background: #ff7c5f;
	    border-radius: 500px;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    margin-left: 0;
	    color: white;

		  i {
			  font-size: 1.2rem;
			  position: relative;
			  bottom: -2px;
			}

		  .menu {
			  border-radius: .75rem;
			  margin: .5rem 0;
			  box-shadow: none;
			  border-color: #f3f3f3;
			  overflow: hidden;

			  .item {
				  border-radius: 0;

				  &:hover {
					  font-weight: 600 !important;
					  color: #000 !important;
					}
				}
			}
		}
	}

	.ui.cards {
		.card {
		  border-radius: 0.75rem;
		  overflow: hidden;

		  .cover {
		  	padding: 0;
    		display: flex;

		  	img {
		  		border-radius: .75rem;
		  	}

		  	time {
				  position: absolute;
				  top: 1rem;
				  left: 0;
				  background: rgb(71 51 255 / 48%);
				  padding: .75rem 1rem .5rem .5rem;
				  line-height: 1;
				  border-radius: 0 .75rem .75rem 0;
				  font-weight: 600;
				  color: #fff;
				  backdrop-filter: blur(3px);
				}
		  }

		  .content {
			  border: none;
			}

			.title {
		    font-weight: 600;
		    text-align: center;
		    font-size: 1.1rem;
		    line-height: 1.5;
		    display: flex;
		    align-items: center;
		    justify-content: center;
			}

			.description {
			  padding-top: 0;
			  font-size: 1.1rem;
			  text-align: center;
			  line-height: 1.3;
			}
		}
	}
}


#post {
	>.header, >.body >.left >.header {
		padding: 2rem;
		background: linear-gradient(45deg, #fbfbfb, rgba(229, 229, 229, 0.62), #fbfbfb);

		div {
			font-size: 1.8rem;
			line-height: 1.5;
			font-weight: 700;
			background: -webkit-linear-gradient(59deg, #12425d, #4fc0ff 21%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		p {
			font-size: 1.2rem;
			line-height: 1.5;
			font-weight: 600;
			margin-top: 0.75rem;
			color: #4a4848;
			margin-bottom: 0;
		}

		span {
			margin-top: 0.75rem;
			font-size: 1.1rem;
			color: #4e4e4e;
			display: table;
			padding: 0.75rem 1rem 0.5rem;
			border-radius: 0.75rem;
			line-height: 1.5;
			border: 1px solid #eceaea;
			font-weight: 500;
			background: #fff;
		}
	}
	
	>.body {
		display: flex;
		flex-wrap: wrap;
	
		> div {
			flex: 1;
			padding: 2rem;
		}
	
		> div.left {
			flex: 1;

			> .header {
				display: none;
			}

			.related-posts {
				.header {
				  font-size: 1.5rem;
				  font-weight: 600;
				  margin-bottom: 1rem;
				  display: block;
				}

				.card {
				  border-radius: 0.75rem;
				  overflow: hidden;

				  .cover {
				  	padding: 0;
		    		display: flex;

				  	img {
				  		border-radius: .75rem;
				  	}

				  	time {
						  position: absolute;
						  top: 1rem;
						  left: 0;
						  background: rgb(71 51 255 / 48%);
						  padding: .75rem 1rem .5rem .5rem;
						  line-height: 1;
						  border-radius: 0 .75rem .75rem 0;
						  font-weight: 600;
						  color: #fff;
						  backdrop-filter: blur(3px);
						}
				  }

				  .content {
					  border: none;
					}

					.title {
				    font-weight: 600;
				    text-align: center;
				    font-size: 1.1rem;
				    line-height: 1.5;
				    display: flex;
				    align-items: center;
				    justify-content: center;
					}

					.description {
					  padding-top: 0;
					  font-size: 1.1rem;
					  text-align: center;
					  line-height: 1.3;
					}
				}
			}

			.social-buttons {
				padding: 2rem;
				margin: 2rem -2rem;
				
				> span {
					font-size: 1.5rem;
					font-weight: 600;
					margin-bottom: 1rem;
					display: block;
				}

				.button {
					font-size: 1.3rem;
				}
			}

		}
	
		> div.right {
			max-width: 350px;
    	background: #fff;
			box-shadow: -5px 0px 14px 7px rgb(128 128 128 / 5%);
			position: relative;

			input {
				border-radius: 0.75rem !important;
				height: 45px;
			}

			.categories .items-list {
			  display: flex;
			  flex-wrap: wrap;
			  margin: -.5rem;
			  margin-top: .5rem;

			  a {
					margin: .5rem;
					white-space: nowrap;
					padding: .75rem;
					background: #ffffff;
					border-radius: .5rem;
					font-size: 1.1rem;
					font-weight: 500;
					max-width: 150px;
					overflow: hidden;
					width: 100%;
					text-overflow: ellipsis;

					&:hover {
						color: #000;
						box-shadow: 0 0 25px 0px rgb(151 151 151 / 26%);
						font-weight: 600;
					}
				}

			}

			.items-title {
			  text-align: center;
			  position: relative;
			  font-size: 1.5rem;
			  font-weight: 600;
			  margin-bottom: 1rem;
			  line-height: 1.5;
			  color: #1d1d1d;

			  &:before, &:after {
				  content: '';
				  position: absolute;
				  background: #f2f2f3;
				  border-radius: 1rem;
				  height: 1rem;
				  width: 20%;
				  top: calc(50% - .5rem);
				}

				&:before {
				  left: 0;
				}

				&:after {
				  right: 0;
				}
			}

			.latest-posts {
				.item {
					display: flex;
					align-items: center;

					.cover {
						width: 90px;
						height: 90px;
						margin-right: 1rem;
						min-width: 90px;
						min-height: 90px;
						border-radius: 500px;
						background-position: center;
						background-size: cover;
						border: .25rem solid #fff;
						display: flex;
					}

					&:not(:last-child) {
						margin-bottom: 1.25rem;
					}

					.content {
						flex: 1;

						.name {
							font-weight: 500;
							font-size: 1.1rem;
							line-height: 1.5;
						}

						.date {
							font-weight: 500;
							font-size: .9rem;
							margin-top: 0.75rem;
							color: #313131;
						}
					}
				}
			}

		}

		.post-cover {
			display: flex;
			margin-bottom: 2rem;

			img {
				border-radius: 0.5rem;
			}
		}
	}
}


#prepaid-credits {
	>.header {
    padding: 1rem 2rem;
    background: #fff1e8;
    position: relative;
    overflow: hidden;

		&:before {
		  content: "";
		  position: absolute;
		  left: -5%;
		  top: 0;
		  width: 33%;
		  height: 100%;
		  background: linear-gradient(220deg, #ffa457, #ff8787) !important;
		  transform: skewX(20deg);
		  border-right: 0.75rem solid #fff;
		}

		* {
		  position: relative;
		  color: #fff;
		}
	
		.title {
			font-size: 1.8rem;
			line-height: 1.5;
			font-weight: 700;
		}
	}
	
	>.body {
		font-size: 1.1rem;
    line-height: 1.5;
    display: flex;

    > div {
		  flex: 1;
		  padding: 3rem;

		  &.right-side {
			  max-width: 400px;
			  width: 100%;
			  position: relative;
			  overflow-y: clip;

			  &:before {
				  content: "";
				  position: absolute;
				  top: 0;
				  left: 0;
				  width: 100%;
				  height: 100%;
				  box-shadow: -5px 0px 14px 7px rgb(128 128 128 / 5%);
				}

				.methods {
			    font-size: 1.2rem;
			    font-weight: 600;
			    margin-bottom: 2rem;
			    position: relative;

				  >.header {
						font-size: 1.4rem;
				    font-weight: 600;
				    margin-bottom: 1rem;
					}

				  .ui.dropdown {
					  border-radius: 0.75rem !important;
					  box-shadow: none;

					  .text {
						  align-items: center;
						  display: flex;

						  img {
							  border-radius: 100px;
							  max-width: 34px;
							  width: 100%;
							  height: 100%;
							  max-height: 34px;
							}
						}

					  .menu {
					    box-shadow: none;
					    border-radius: 0.75rem;
					    margin-top: 1rem;
					    margin-bottom: 1rem;
					    border: none;
					    background: #fff;
					    overflow: hidden;
					    max-height: 300px;

						  &::-webkit-scrollbar {
							  width: 5px;
							  height: 5px;
							}

							&:hover {
								overflow: auto;
							}

							&::-webkit-scrollbar-track {
							  background: #EAEAEA;
							}

							&::-webkit-scrollbar-thumb {
							  background: #b061ff;

							  &:hover {
								  background: #582a86;
								}
							}

						  &:hover {
						  	overflow: auto;
						  }
						}

					  .item {
					    display: flex;
					    align-items: center;
					    padding: 1.5rem 1.5rem 1rem!important;

					    &:not(:last-child) {
							  border-bottom: 1px solid #dddddd;
							}

					    &:hover, &.active, &.selected {
								background: #fff;
								font-weight: 600;
							}

							.content {
								min-width: 0;
							}

						  img {
						    width: 50px;
						    height: 50px;
						    min-height: 50px;
						    min-width: 50px;
						    margin-right: 1rem;
								border-radius: 100px;
							}

							.name {
						    font-size: 1.3rem;
						    text-transform: capitalize;
						    font-weight: 600;
						    line-height: 1;
							}

							.description {
						    margin-top: 1rem;
						    line-height: 1.5;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
							}
						}
					}
				}

				.checkout {
					margin-top: 2rem;
					position: relative;

					.terms {
						margin-bottom: 1rem;
						a {
							font-weight: 600;
						}
					}

					.button {
				    border-radius: 0.75rem;
				    font-weight: 600;
				    font-size: 1.2rem;
				    display: flex;
				    align-items: center;
				    justify-content: center;
				    padding: 1.5rem 1.5rem 1rem;
				    background: #ffe73e;
				    color: #000;
				    text-transform: uppercase;

					  &:hover {
						  font-weight: 700;
						}
					}
				}
			}

			&.left-side {
				position: relative;
				z-index: 0;

				> .header {
					display: none;
					position: relative;

					&:before {
					    content: '';
					    position: absolute;
					    bottom: 20px;
					    left: 0;
					    height: 20px;
					    width: 80%;
					    box-shadow: 2px 12px 10px 5px grey;
					    z-index: -1;
					    transform: rotate(-2deg);
					    background: transparent;
					}
				}
			}
		}
	}

	.card {
		background: #f6f6f7;
		border-radius: .75rem;

		&.popular {
		  .header {
		  	border-right: 10px solid #27b3cb;
		  }
		}

		.content {
			border: none;
			text-align: center;
			font-weight: 500;
		}

		.content.header {
			font-size: 1.3rem;
			position: relative;
			border-radius: 0.75rem 0.75rem 0 0 !important;

			.price {
				margin-top: 1rem;
				font-size: 1.8rem;
			}

			.name {
				font-weight: 600;
			}

			.tag {
			  position: absolute;
			  transform: rotate(90deg);
			  right: -62px;
			  top: 54px;
			  padding: 0.5rem 1rem;
			  font-weight: 600;
			  background: #27b3cb;
			  border-radius: 0.75rem 0.75rem 0 0;
			  font-size: 1rem;
			  color: #fff;
			  z-index: 1;
			}
		}

		.content.body {
			background: #fff;
		}

		.content.footer {
			.button {
		    background: #fff !important;
		    box-shadow: none;
		    border-radius: 0.5rem;
		    font-weight: 600;
		    color: #000 !important;

		    &:hover, &.active {
					background: linear-gradient(220deg, #ffa457, #ff8787) !important;
					color: #fff !important;
		    }
			}
		}

		&.active {
			.content.footer {
				.button {
					background: linear-gradient(220deg, #ffa457, #ff8787) !important;
					color: #fff !important;
				}
			}
		}
	}
}



#single-page {
	>.header {
    padding: 1rem 2rem;
    background: #ffedd9;
    position: relative;
    overflow: hidden;

		&:before {
		  content: "";
		  position: absolute;
		  left: -5%;
		  top: 0;
		  width: 33%;
		  height: 100%;
		  background: #ff6f6f;
		  transform: skewX(20deg);
		  border-right: 0.75rem solid #fff;
		}

		* {
		  position: relative;
		  color: #fff;
		}
	
		.title {
			font-size: 1.8rem;
			line-height: 1.5;
			font-weight: 700;
		}
	}
	
	>.body {
		padding: 3rem;
		font-size: 1.1rem;
    line-height: 1.5;
	}
}


#support {
	>.header {
    padding: 1rem 2rem;
    background: #ffedd9;
    position: relative;
    overflow: hidden;
			
		* {
			position: relative;
			color: #fff;
		}

		&:before {
			content: '';
			position: absolute;
			left: -5%;
			top: 0;
			width: 33%;
			height: 100%;
			background: #ff6f6f;
			transform: skewX(20deg);
			border-right: 0.75rem solid #fff;
		}

		.title {
			font-size: 1.8rem;
			line-height: 1.5;
			font-weight: 700;
		}
	}

	>.body {
		font-size: 1.1rem;
    line-height: 1.5;
    display: flex;
    flex-wrap: wrap;

    > .header {
    	display: none;
    }

    > div {
    	flex: 1;
    	padding: 3rem;

    	&.right-side {
    		max-width: 400px;
    		width: 100%;
    		position: relative;
    		overflow-y: clip;

    		&:before {
				  content: '';
				  position: absolute;
				  top: 0;
				  left: 0;
				  width: 100%;
				  height: 100%;
				  box-shadow: -5px 0px 14px 7px rgb(128 128 128 / 5%);
				}

				input, textarea {
					min-height: 45px;
					border-radius: .75rem;
				}

				.button {
					border-radius: .75rem;
				}
    	}

    	&.left-side {
    		* {
    			font-family: Valexa,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
    		}

    		.faq {
    			> .header {
    				font-weight: 600;
				    font-size: 1.3rem;
				    margin-bottom: 1.5rem;
    			}

    			.items {
    				display: flex;
						flex-wrap: wrap;
						margin: -1rem;
    			
	    			.item {
							width: calc(50% - 2rem);
							margin: 1rem;
							border-radius: .75rem;
							overflow: hidden;
							.header {
								display: flex;
								align-items: center;
								min-height: 25px;
								line-height: 1.5;
								font-weight: 600;
								padding: 1rem;
								background: aliceblue;
								cursor: pointer;
								img {
									width: 25px;
									height: 25px;
									margin-right: .75rem;
									position: relative;
									top: -2px;
								}
							}
							.answer {
								display: none;
								padding: 1.5rem;
								box-shadow: inset 0 0 52px 3px rgb(128 128 128 / 5%);
								border-radius: 0 0 .75rem .75rem;
							}
						}
    			}
				}
    	}
    }
	}
}


#recent-purchase-popup {
	position: fixed;
	max-width: 310px;
	bottom: 1rem;
	left: 1rem;
	background: rgba(255, 255, 255, 0.64);
	width: 100%;
	border-radius: 1rem;
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	display: none;
	z-index: 99;
	box-shadow: 0 0 20px 10px rgb(0 0 0 / 4%);
	min-height: 140px;
	max-height: 140px;
	height: 100%;
	
	* {
		color: #000;
	}

	.content {
		display: flex;
		position: relative;
		padding: 1rem;
		align-items: center;
		height: 100%;
	}

	.cover {
    width: 120px;
    height: 100%;
    min-width: 120px;
    min-height: 140px;
    border-radius: 1rem 0 0 1rem;
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    top: 0;
	}

	.details {
		margin-left: 120px;

		.header {
			display: flex;
			align-items: center;
			margin-bottom: .5rem;

			.name {
				font-weight: 700;
				text-transform: capitalize;
			}

			img {
			  width: 35px;
			  height: 35px;
			  border-radius: 500px;
			  margin-right: 0.5rem;
			}
		}

		.item {
			margin: .5rem 0 0;

			span {
			  font-weight: 600;
			  text-transform: capitalize;
			  color: #000;
			}
		}

		.price {
	    font-weight: 600;
	    position: absolute;
	    left: 0.5rem;
	    bottom: 0.5rem;
	    line-height: 1;
	    background: #c33764c7;
	    padding: 0.5rem;
	    font-family: system-ui;
	    border-radius: 0.5rem;
	    color: #fff;
	    backdrop-filter: blur(5px);
		}
	}

	.close {
		position: absolute;
		right: 1rem;
		bottom: 1rem;
		cursor: pointer;
	}
}


html.fullwide {
	&, body {
		overflow-y: auto;
	}

	.cards.products.five .card .image {
	  height: 270px !important;
	  max-height: 270px !important;
	}

	#right-section {
		margin: auto;
		max-width: 1600px;
		height: initial;
	}

	#left-section {
		display: none;
	}
	
	#top-menu {
		.ui.menu {
			max-width: 1450px;
			
			.item.logo {
				display: flex;
			}
		}
	}
	
	#categories {
		box-shadow: inset 0 0 20px 0px #f6f6f6;
		padding: 1rem 0;
		
		.wrapper {
			margin: 0 1rem;
		}
	}
	
	#top-panel {
		min-height: 420px;
	}
	
	#body {
		padding-top: 2rem;
	
		> .selection {
			> .header, > .ui.cards {
				max-width: 1450px;
				margin-left: auto;
				margin-right: auto;
			}
			
			> .header {
				margin-right: auto;
				margin-left: 1.5rem;
			}
		}
	}
	
	#items {
		max-width: 1400px;
		margin: auto;
		padding-top: 0;
	}
	
	#item {
		margin-top: -2rem;
		
		> .ui.breadcrumb {
			display: none;
		}
		
		>.container {
			max-width: 1300px;
			margin: auto;
			overflow: hidden;
			
			> .left-side {
				position: relative;
				z-index: 0;

				> .ui.breadcrumb {
					display: block;
					position: relative;
					background: #ffffff;
					
					&:before {
						content: '';
						position: absolute;
						bottom: 20px;
						left: 0;
						height: 20px;
						width: 80%;
						box-shadow: 2px 12px 10px 5px grey;
						z-index: -1;
						transform: rotate(-2deg);
						background: transparent;
					}
				}

				> .header {
					flex-direction: row-reverse;

					> div.content {
					  padding: 2rem 2rem 2rem 0;
					}
				}
			}
			
			> .right-side {
				padding-top: 2.5rem;
			}
		}
	}
	
	#prepaid-credits {
		margin-top: -2rem;
		max-width: 1300px;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
		
		> .header {
			display: none;
		}
		
		.left-side {			
			> .header {
				position: relative;
				margin: 0 -3rem 3rem;
				background: #fff;
				min-height: 80px;
				display: flex !important;
				align-items: center;
				padding: 1rem 3rem;
				
				.title {
					font-size: 1.8rem;
					line-height: 1.5;
					font-weight: 600;
				}

				* {
					font-weight: 600;
					color: #2a2a2a;
				}
				
				&:before {
					content: '';
					position: absolute;
					bottom: 20px;
					left: 0;
					height: 20px;
					width: 80%;
					box-shadow: 2px 12px 10px 5px grey;
					z-index: -1;
					transform: rotate(-2deg);
					background: transparent;
				}
			}
		}
	}
	
	#single-page {
		margin-top: -2rem;
		width: 100%;
		background: linear-gradient(87deg, rgba(245, 245, 245, 0), whitesmoke, rgba(245, 245, 245, 0));

		> div {
		  max-width: 900px;
		  margin: auto;
		}

		> .header {
	    min-height: 80px;
	    display: flex;
	    align-items: center;
	    padding: 3rem 3rem 0;
	    background: transparent;
			
			&:before {
				display: none;
			}
			
			* {
				color: #000;
			}
		}
	}
	
	#blog {
	    margin-top: -2rem;
		position: relative;
				
		> .ui.secondary.menu {
			padding: 1rem;
			margin: -2rem -2rem 2rem;
			box-shadow: none;
			max-width: 1300px;
			margin-left: auto;
			margin-right: auto;
			height: 85px;
			
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				height: 85px;
				width: 100%;
				background: #f9f9f9;
			}
			
			> .header {				
				background: none;
				-webkit-background-clip: unset;
				-webkit-text-fill-color: unset;
			}
			
			.ui.dropdown {
				background: #764bc9;
				color: white;
				border-radius: .75rem;
			}
		}
		
		>.ui.cards {
			max-width: 1300px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 2rem;
		}
	}
	
	#post {
		overflow: hidden;
		max-width: 1300px;
		margin: -2rem auto 0;
		
		> .header {
			display: none;
		}
		
		>.body {
			>.left {
				padding-top: 0;
				position: relative;
				z-index: 0;
				
				>.header {
					display: block;
					margin-bottom: 2rem;
					margin: 0 -2rem 2rem;
					background: #fff;
					position: relative;
					
					&:before {
						content: '';
						position: absolute;
						bottom: 20px;
						left: 0;
						height: 20px;
						width: 80%;
						box-shadow: 2px 12px 10px 5px grey;
						z-index: -1;
						transform: rotate(-2deg);
						background: transparent;
					}
				}
			}
		}
	}

	#user {
	  margin: -2rem auto 0;
	  background: radial-gradient(#e4e4e4, transparent);
	  
	  td {
		  background: #fff !important;
		}

	  > .profile {
		  margin: auto;
		  padding-top: 2rem;

		  > .header:not(.extra) {
			  max-width: 1000px;
			  margin: auto;
			  background: transparent;
			}

		  > form {
			  padding: 2rem;
			  max-width: 1000px;
			  margin: auto;
			}
		}

		> .items {
		 	&:not(.profile) {
		    .items-list {
		    	background: transparent;

		    	.titles {
					  background: #919191;
					}
		    }
		 	}

		 	&.credits {
		 		max-width: 1300px;
		 		width: 100%;
		 		margin: auto;
		 	}

		 	.header.extra {
				background: white;
				display: flex;
				align-items: center;
				padding: 3rem 0;

				.title {
				  font-size: 2.2rem;
				  line-height: 1.5;
				  font-weight: 800;
				  max-width: 1100px;
				  width: 100%;
				  padding: 0 2rem;
				  background: -webkit-linear-gradient(42deg, #7e00ff, #ffbc5c 20%);
				  -webkit-background-clip: text;
				  -webkit-text-fill-color: transparent;
				  margin: auto;
				}

			  &:before {
			  	display: none;
			  }
			}

			.table-wrapper {
			  padding: 2rem;
			  border-radius: 1rem;
			  max-width: 1100px;
			  margin: auto;
			}
		}
	}

	#pricing {
    margin-top: -2rem;

    > div {
		  max-width: 1200px;
		  margin: auto;
		  background: transparent;
		}

    > .title {
    	border-bottom: none;
    	padding: 4rem 2rem;
    	text-align: center;
	    max-width: 100%;
	    background: linear-gradient(87deg, #f5f5f500, whitesmoke, #f5f5f500);

    	.header {
		    font-size: 3rem;
		    font-weight: 800;
		    background: -webkit-linear-gradient(45deg, #ff817e 50%, #4c4c4c 50%), -webkit-linear-gradient(45deg, #ff817e 50%, #4c4c4c 50%);
		    -webkit-background-clip: text;
		    -webkit-text-fill-color: transparent;
			}

			.subheader {
			  font-size: 1.8rem;
			  font-weight: 700;
			  color: #cbcbcb;
			  background: -webkit-linear-gradient(45deg, #b3b3b3 50%, #6f6d7e 50%);
			  -webkit-background-clip: text;
			  -webkit-text-fill-color: transparent;
			}
    }

    .card {
    	.content {
    		&.header:before {
				  background: #f7f7f7;
				}
    	}

    	.item .icon.included {
			  background: #ff7673 !important;
			}
    }
	}

	#support {
		margin-top: -2rem;
		max-width: 1300px;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
		
		> .header {
			display: none;
		}
		
		> .body {
			.left-side {
				position: relative;
				z-index: 0;
				
				> .header {
					position: relative;
					margin: 0 -3rem 3rem;
					background: #fff;
					min-height: 80px;
					display: flex !important;
					align-items: center;
					padding: 1rem 3rem;
					font-size: 1.8rem;
					line-height: 1.5;
					font-weight: 600;
					
					&:before {
						content: "";
						position: absolute;
						bottom: 20px;
						left: 0;
						height: 20px;
						width: 80%;
						box-shadow: 2px 12px 10px 5px grey;
						z-index: -1;
						transform: rotate(-2deg);
						background: transparent;
					}
				}
			}
		}
	}

	#checkout-page {
		margin-top: -2rem;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
		overflow-x: clip;
		
		> .header {
			display: none;
		}
		
		> .container .left-side {
			position: relative;
			z-index: 0;
			
			> .header {
				position: relative;
				margin: 0 -3rem 3rem;
				background: #fff;
				display: block !important;
				padding: 1rem 3rem;
				font-size: 1.8rem;
				line-height: 1.5;
				font-weight: 700;
				
				&:before {
					content: "";
					position: absolute;
					bottom: 20px;
					left: 0;
					height: 20px;
					width: 80%;
					box-shadow: 2px 12px 10px 5px grey;
					z-index: -1;
					transform: rotate(-2deg);
					background: transparent;
				}

				.subheader {
					margin-top: 0.5rem;
					font-size: 1.3rem;
					color: #101010;
					font-weight: 600;
				}
			}
		}
	}

	#footer .counters.cards {
    margin: 0 auto 1rem;
    max-width: 1300px;
	}
}

@media (max-width: 1500px) {
	#item {
		.container {
			> .right-side {
				display: none;
			}
		}
	}
}


@media (max-width: 1440px) {
	#body {
		.ui.five.cards.products {
			.card {
				width: calc(25% - 1.5em);
			}
		}
	}
}



@media (max-width: 1279px) {
	#body {
		.ui.five.cards.products {
			.card {
				width: calc(33.3334% - 1.5em);
			}
		}
	}
}


@media (max-width: 1220px) {
	#top-menu {
		.ui.menu {
			> .item.search {
				display: none;
			}
		}
	}

	#pricing {
		.cards {
			padding: 0.5rem 1rem;
		}
	}
}

@media (min-width: 1025px) {
  .mobile-only {
      display: none !important;
  }
}


@media (max-width: 1024px) {
	.desktop-only {
      display: none !important;
  }

  #checkout-page {
  	> .container {
  		flex-direction: column;

  		> div {
  			max-width: unset !important;
  			width: 100%;
  		}
  	}
  }

  #support {
  	> .body {
  		flex-direction: column;

  		> div {
  			max-width: unset !important;
  			width: 100% !important;
  			padding: 2rem !important;
  		}	
  	}
  }

  #post > .body {
  	flex-direction: column;

  	> div {
  		max-width: unset !important;
  		width: 100%;
  	}
  }

  #prepaid-credits {
  	> .body {
  		flex-direction: column;
  	}
  }

	#left-section {
		display: none;
		position: fixed;
		background: #fff;
		z-index: 9;
		width: calc(100% - 78px);
        height: 100vh;
		
		&:before {
			width: 60px;
		}
		
		> .menu .item .image {
			max-width: 60px;
			min-width: 60px;
		}
	}
	
	#top-menu {
		.ui.menu {
			> .item.logo {
				display: flex;
				align-items: center;
    			justify-content: center;
				padding: 0;
			}
			
			.mobile-menu-toggler {
				display: flex !important;
				margin-right: 0;
			}
		}
	}
	
	#body {
		.ui.five.cards.products {
			.card {
				width: calc(25% - 2em);
			}
		}
	}
}


@media (max-width: 820px) {
	#checkout-page > .container {
		> div {
			padding: 2rem;
		}

		.left-side .items {
			flex-direction: column;
			margin: 0;

			.item.product {
				width: 100%;
				margin: 0;
				background: #f8f8f8;

				&:not(:last-child) {
					margin-bottom: 1rem;
				}
			}
		}
	}

	#body {
		.ui.five.cards.products {
			.card {
				width: calc(33.3334% - 2em);
			}
		}
	}
	
	#top-menu {
		.ui.menu {
			> .item {
				&.blog, &.pricing, &.credits {
					display: none;
				}
			}
		}
	}
}


@media (max-width: 768px) {
	#item {
		.container {
			.left-side {
				> .header {
					flex-direction: column-reverse !important;
					
					> div {
						&.content {
							padding: 2rem !important;
						}
						
						&.image {
							max-width: 600px;
						}
					}
				}
			}
		}
		
		.ui.breadcrumb {
			background: linear-gradient(45deg, #ededed, transparent);
			
			.active.section {
				color: #ff9d5b;
			}
		}
	}	
}


@media (max-width: 660px) {
	html.fullwide {
		#pricing > .title .header {
			font-size: 2.5rem;
		}
	}

	#body {
		.ui.five.cards.products {
			.card {
				width: calc(50% - 2em);
			}
		}
	}
	
	#footer {
		.newsletter .wrapper {
			flex-direction: column;
		}
	}
}


@media (max-width: 479px) {
	#top-menu {
		.ui.menu {
			> .item {
				&.logo {
					padding-left: 0;
				}
				
				&.mobile-menu-toggler, &.user .default img {
					width: 35px;
					height: 35px;
					min-width: 35px;
					min-height: 35px;
				}
				
				&.user {
					margin-left: auto
				}
				
				&.cart, &.notifications {
					display: none;
				}
			}
		}
	}
}


@media (max-width: 360px) {
	#body {
		.ui.five.cards.products {
			.card {
				flex: 100%;
			}
		}
	}
}